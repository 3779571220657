import {ResourceUnitType} from "../constants";
import _ from "lodash";
import store from "../redux/store";
import {formatValue as formatCurrency} from 'react-currency-input-field';

const costFactorCalculator = ({quantity, unit, days = 1}) => {
    switch (unit) {
        case ResourceUnitType.Item:
            return quantity;
        case ResourceUnitType.Day:
            return quantity * days;
        default:
            return 1;
    }
};

const defaultCostFactorMapper = m => ({
    quantity: m?.Quantity || 0,
    cost: m?.Cost || 0,
    unit: m?.Unit || "Item",
    days: 1
});

const getTotalCost = (sources, factorMapper = defaultCostFactorMapper, factorCalculator = costFactorCalculator) => {
    const src = sources || [];

    const total = _.sum(src.map(s => {
        const factorComponents = factorMapper(s);
        const {cost} = factorComponents;
        const factor = factorCalculator(factorComponents);

        return cost * factor;
    }));

    return total;
};

const currencyConfig = (value = null, fractions = true) => {
    const currency = store.getState().projects.activePortfolio?.Currency;
    const {fractionSize = 0, groupSeparator, decimalSeparator, symbol} = currency || {};
    const {template = '$1', grapheme = ''} = symbol || {};
    const pattern = template.replace('1', '').replace('$', grapheme);

    const config = {
        value: value?.toString(),
        groupSeparator: groupSeparator === '' ? '' : groupSeparator || ',',
        decimalSeparator: decimalSeparator === '' ? '' : decimalSeparator || '.',
        decimalsLimit: fractionSize,
        decimalScale: fractions ? fractionSize : undefined,
        prefix: template[0] === '$' ? pattern : null,
        suffix: template[0] === '1' ? pattern : null,
        allowNegativeValue: true,
    };

    return config;
}

const formatAmount = (amount = 0) => {
    const config = currencyConfig(amount, amount % 1 !== 0);

    return formatCurrency(config);
};

const formatTotalCost = (sources, factorMapper = defaultCostFactorMapper, factorCalculator = costFactorCalculator) => {
    const total = getTotalCost(sources, factorMapper, factorCalculator);

    return formatAmount(total);
};


const moneyFormatter = (value, info = null) => info?.userTyping ? info.input : formatAmount(value);
const moneyParser = value => {
    const cfg = currencyConfig();
    const symbol = (cfg.suffix || cfg.prefix || '$').replace('$', '\\$',);
    const regexp = new RegExp(`${symbol}\s?|(,*)`, 'g');

    const replaced = value.replace(regexp, '');
    return replaced;
};

export {
    costFactorCalculator,
    defaultCostFactorMapper,
    getTotalCost,
    formatTotalCost,
    formatAmount,
    moneyFormatter,
    moneyParser,
    currencyConfig
};
