import React, {memo, useState} from "react";
import moment from "moment";
import {message, Popover} from "antd";
import FilterBar from "./FilterBar/FilterBar";
import {endLoading, startLoading} from "../../redux/reducers/detailsPanel";
import {useDispatch, useSelector} from "react-redux";
import GroupTimelineTable from "./GroupTimelineTable";
import {
    CheckOutlined,
    ExclamationCircleFilled,
    FileTextFilled,
    QuestionOutlined,
    ToolFilled,
    ToolOutlined,
} from "@ant-design/icons";
import {formatCrewTeamMemberName, formatTimelineTeamMemberName} from "../../helpers/text";
import {ApiTimelineItemStatus, SidePanelItemType} from "../../constants/constants";
import {formatDuration} from "../../helpers/time";
import {getEvent, shift} from "../../services/timeline";
import TableWithGroups from "../Common/Tables/TableWithGroups";
import useDateTimeFormat from "../../hooks/useDateTimeFormat";
import EditAction from "../Common/Tables/Actions/EditAction";
import CloneAction from "./Actions/CloneAction";
import CloneFromExisting, {onCloneBookings} from "./CloneFromExisting";
import Shift from "../Common/Modal/Shift";
import ShiftEventAction from "./Actions/ShiftEventAction";
import classNames from "classnames";
import {columnVisibilityFilter} from "../Common/Tables/ColumnsVisibility/ColumnsVisibility";
import {TagIcon} from "../../helpers/Tags";
import {ProjectTagForId} from "../Common/Projects/ProjectsTag";
import {BookingsTableFooter, GroupBookingsTableFooter} from "./TimelineTableFooter";
import {sanitizeHTML} from "../../helpers/html";

const TimelineItemStatusIcon = {
    [ApiTimelineItemStatus.None]: "",
    [ApiTimelineItemStatus.Tentative]: <QuestionOutlined/>,
    [ApiTimelineItemStatus.Confirmed]: <CheckOutlined/>,
};

const shouldTimeCellUpdate = (curr, prev) =>
    curr.EndDateTime != prev.EndDateTime || curr.StartDateTime != prev.StartDateTime || curr.IsTimeOnly != prev.IsTimeOnly;

const formatDay = (start = null, end = null, isTimeOnly) => {
    if (isTimeOnly) return;

    const mStart = moment(start);
    const mEnd = moment(end);

    if (!mStart.isValid() && !mEnd.isValid()) {
        return;
    }

    if (mStart.isValid() && !mEnd.isValid()) {
        return mStart.format("ddd");
    }

    const daysDiff = mEnd.diff(mStart, "days");
    return daysDiff === 0 ? (
        mStart.format("ddd")
    ) : (
        <div>
            {mStart.format("ddd")} <br/> {mEnd.format("ddd")}
        </div>
    );
};

const formatLocationName = (location) =>
    location?.Name === location?.ParentName ? location?.Name : `${location?.Name} (${location?.ParentName})`;

const formatDate = (start = null, end = null, formats, isTimeOnly) => {
    if (isTimeOnly) return;

    const mStart = moment(start);
    const mEnd = moment(end);
    const now = moment();

    const formatStart = now.year() !== mStart.year() ? formats.dateLongFormat : formats.dateLongNoYearFormat;
    const formatEnd = now.year() !== mEnd.year() ? formats.dateLongFormat : formats.dateLongNoYearFormat;

    if (!mStart.isValid() && !mEnd.isValid()) {
        return;
    }

    if (mStart.isValid() && !mEnd.isValid()) {
        return mStart.format(formatStart);
    }

    const daysDiff = mEnd.diff(mStart, "days");

    return daysDiff === 0 ? (
        mStart.format(formatStart)
    ) : (
        <div>
            {mStart.format(formatStart)} &ndash;
            <br/> {mEnd.format(formatEnd)}
        </div>
    );
};

const formatTime = (isAllDay, start, end, formats) => {
    if (isAllDay) return "";

    const mStart = moment(start);
    const mEnd = moment(end);

    if (!mStart.isValid() && !mEnd.isValid()) {
        return;
    }

    if (mStart.isValid() && !mEnd.isValid()) {
        return mStart.format(formats.time);
    }

    const daysDiff = mEnd.diff(mStart, "days");
    const minutesDiff = mEnd.diff(mStart, "minutes");
    return daysDiff === 0 ? (
        minutesDiff === 0 ? (
            mStart.format(formats.time)
        ) : (
            <>
                {mStart.format(formats.time)} &ndash; {mEnd.format(formats.time)}{" "}
            </>
        )
    ) : (
        <div>
            {mStart.format(formats.time)} <br/> {mEnd.format(formats.time)}
        </div>
    );
};

const LocationsLine = ({item, projects}) => {
    return (
        <>
            {item.Locations?.map((location) => (
                <div key={location?.ContactId}>
                    <LocationConflicts item={item} projects={projects}
                                       specificLocationContactId={location?.ContactId}/>{" "}
                    {formatLocationName(location)}
                </div>
            ))}
        </>
    );
};

export const LocationConflicts = ({item, className = "", projects, specificLocationContactId}) => {
    if (!item?.LocationConflicts || item?.LocationConflicts.length === 0) {
        return null;
    }

    if (!specificLocationContactId) {
        return <ExclamationCircleFilled className={["warning-icon", className].join(" ")}/>;
    }
    const conflicts = item.LocationConflicts.filter((conflict) => conflict.LocationContactId === specificLocationContactId);

    if (conflicts.length === 0) return null;

    return (
        <Popover
            content={
                <>
                    {conflicts.map((conflict = {}) => (
                        <div key={conflict.Id}>
                            {conflict.Name} [{(projects || []).find((p) => p.Id === conflict.ProjectId).Name}]
                        </div>
                    ))}
                </>
            }
            title="Conflicting Events:"
            trigger="hover">
            <ExclamationCircleFilled className={["warning-icon", className].join(" ")}/>
        </Popover>
    );
};

export const BookingsConflicts = memo(({bookingConflicts, className}) => {
    if (!bookingConflicts || bookingConflicts.length === 0) {
        return null;
    }
    return (
        <Popover
            content={
                <>
                    {bookingConflicts.map((conflict) => (
                        <div key={conflict.Id}>
                            {conflict.TaskName} [{conflict.ProjectName}]
                        </div>
                    ))}
                </>
            }
            title="Booking Conflicts:"
            trigger="hover">
            <ExclamationCircleFilled className={["warning-icon", className].join(" ")}/>
        </Popover>
    );
});

const LabourLinesColumn = memo(({labourLines = []}) => {
    const positions = useSelector((state) => state.labour.positions);

    function getPositionText(positionId) {
        const position = positions.find((p) => p.Id === positionId);
        return position ? `${position.Name}` : "Unknown";
    }

    return labourLines.map((ll) => (
        <div key={ll.Id}>
            {getPositionText(ll.PositionId)} ({ll.Quantity})
        </div>
    ));
});

const defaultColumns = {
    name: {
        title: "Event",
        dataIndex: "Name",
        key: "Id",
        alwaysVisible: true,
        width: "20%",
    },
    events: {
        title: "",
        dataIndex: "Event",
        key: "Event",
        fixedWidth: true,
        width: "50px",
        render: (a, record) => (
            <>
                {record.StatusTag?.Icon && <TagIcon icon={record.StatusTag?.Icon}/>}
                {record.HasDeliverables && <FileTextFilled/>}
                {record.LabourLines?.length > 0 && !record.HasBookings && <ToolOutlined/>}
                {record.HasBookings && <ToolFilled/>}
            </>
        ),
    },
    day: {
        className: "dates-column",
        title: "Day",
        dataIndex: "ProjectDate",
        width: "46px",
        alwaysVisible: true,
        render: (d, record) => formatDay(record.StartDateTime, record.EndDateTime, record.IsTimeOnly),
        shouldCellUpdate: shouldTimeCellUpdate,
    },
    date: {
        className: "dates-column",
        title: "Date",
        dataIndex: "ProjectDate",
        alwaysVisible: true,
        width: "86px",
        shouldCellUpdate: shouldTimeCellUpdate,
    },
    time: {
        className: "dates-column",
        title: "Time",
        dataIndex: "ProjectDate",
        alwaysVisible: true,
        width: "91px",
        shouldCellUpdate: shouldTimeCellUpdate,
    },

    project: {
        className: "project-column",
        title: "Project",
        dataIndex: "ProjectId",
        render: (projectId) => <ProjectTagForId projectId={projectId}/>,
        width: "10%",
        shouldCellUpdate: () => false,
    },
    location: {
        title: "Location",
        dataIndex: "Location",
        width: "10%",
    },
    team: {
        title: "Contacts",
        dataIndex: "TeamMembers",
        width: "10%",
        render: (t, record) => (
            <>
                <BookingsConflicts bookingConflicts={record.BookingConflicts}/>{" "}
                {(record.PeopleOrganizations || []).map(formatTimelineTeamMemberName).join(", ")}{" "}
            </>
        ),
    },
    crewContact: {
        title: "Name",
        dataIndex: "PeopleOrganizations",
        width: "15%",
        render: (teamMembers, record) => (
            <>
                <BookingsConflicts bookingConflicts={record.BookingConflicts}/>{" "}
                {(teamMembers || []).map((t) => formatCrewTeamMemberName(t)).join(", ")}{" "}
            </>
        ),
    },
    status: {
        title: "Status",
        dataIndex: "Status",
        width: "5%",
        render: (x, record) => record.StatusTag?.Name,
    },
    bookingStatus: {
        title: "",
        dataIndex: "BookingStatus",
        width: "15px",
        fixedWidth: true,
        render: (status = ApiTimelineItemStatus.None) => TimelineItemStatusIcon[status],
        shouldCellUpdate: (record, prevRecord) => record.BookingStatus != prevRecord.BookingStatus,
    },
    duration: {
        title: "Dur.",
        dataIndex: "Duration",
        width: "50px",
        render: (duration) => formatDuration(duration),
        shouldCellUpdate: (record, prevRecord) => record.Duration != prevRecord.Duration,
    },
    labourLines: {
        title: "Labour Lines",
        dataIndex: "LabourLines",
        width: "50px",
        render: (ll) => <LabourLinesColumn labourLines={ll}/>,
    },

    categories: {
        title: "Categories",
        dataIndex: "CategoryTags",
        width: "10%",
        render: (items) => (items || []).map((x) => x.Name).join(", "),
    },
    departments: {
        title: "Departments",
        dataIndex: "DepartmentTags",
        width: "10%",
        render: (items) => (items || []).map((x) => x.Name).join(", "),
    },
    position: {
        title: "Position",
        dataIndex: ["LabourLine"],
        width: "9%",
        alwaysVisible: true,
    },
    details: {
        title: "Details",
        dataIndex: "Notes",
        width: "10%",
        shouldCellUpdate: (record, prevRecord) => record.Notes != prevRecord.Notes,
        render: (data) => {
            const sanitizedHTML = sanitizeHTML(data);
            return <div dangerouslySetInnerHTML={{__html: sanitizedHTML}}/>
        }
    },
};

const TimelineTable = ({items = [], type}) => {
    const dispatch = useDispatch();
    const dateTimeFormats = useDateTimeFormat();
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const allProjects = useSelector((state) => state.projects.portfolioProjects) || [];
    const isLoading = useSelector((state) => state.timeline.isLoading);
    const visibleColumns = useSelector((state) => state.currentUser.user.TablesVisibilitySettings.Timeline.Value || []);

    // state for clone/shift modals support >>>
    const [isCloneEventVisible, setCloneEventVisible] = useState(false);
    const [isShiftVisible, setShiftVisible] = useState(false);
    const [activeItem, setActiveItem] = useState();
    const activeItems = activeItem ? [activeItem] : [];

    // <<< state for clone/shift modals support

    function loadItemToEdit(item) {
        dispatch(
            startLoading({
                item: {Id: item.Id, Name: item.Name, ProjectId: item.ProjectId},
                type,
            })
        );
        getEvent(item.Id, item.ProjectId, item.ItemType)
            .then((item) => dispatch(endLoading({item})))
            .catch(() => message.error("Server error"));
    }

    const dateColumn = {
        ...defaultColumns.date,
        render: (d, record) => (
            <div style={{minWidth: defaultColumns.date.width}}>
                {formatDate(record.StartDateTime, record.EndDateTime, dateTimeFormats, record.IsTimeOnly)}
            </div>
        ),
    };

    const timeColumn = {
        ...defaultColumns.time,
        render: (d, record) => (
            <div style={{minWidth: defaultColumns.time.width}}>
                {formatTime(record.IsAllDay, record.StartDateTime, record.EndDateTime, dateTimeFormats)}
            </div>
        ),
    };

    const locationColumn = {
        ...defaultColumns.location,
        render: (l, record) => (
            <>
                <LocationsLine item={record} projects={allProjects}/>
            </>
        ),
    };

    const eventColumns = [
        defaultColumns.day,
        dateColumn,
        timeColumn,
        defaultColumns.name,
        defaultColumns.events,
        defaultColumns.project,
        locationColumn,
        defaultColumns.team,
        defaultColumns.categories,
        defaultColumns.departments,
        defaultColumns.status,
        defaultColumns.duration,
        defaultColumns.labourLines,
        defaultColumns.details,
    ]
        .filter((col) => columnVisibilityFilter(col, visibleColumns))
        .map((col) =>
            col.alwaysVisible || col.fixedWidth
                ? col
                : {
                    ...col,
                    width: `${80 / visibleColumns.length}%`,
                }
        );

    const crewColumns = [
        defaultColumns.day,
        dateColumn,
        timeColumn,
        defaultColumns.name,
        defaultColumns.project,
        defaultColumns.crewContact,
        defaultColumns.position,
        defaultColumns.bookingStatus,
        {...defaultColumns.duration, title: "Hours", width: "60px"},
        defaultColumns.details,
    ];

    const columnTypes = {
        [SidePanelItemType.Event]: eventColumns,
        [SidePanelItemType.Booking]: crewColumns, //.map((c) => ({ ...c, shouldCellUpdate: () => false })),
    };

    eventColumns.map((c) => ({...c, width: "100px"}));

    const rowClassName = (record) =>
        classNames({
            "row-selected-for-edit": record && itemToEdit && record.Id === itemToEdit.Id,
            favorite: record.IsFavorite,
            "project-task": record.IsProjectTask,
        });

    const onCloseCloneModal = () => {
        setCloneEventVisible(false);
        setActiveItem(null);
    };

    const onOpenCloneEventModal = (record) => {
        setCloneEventVisible(true);
        setActiveItem(record);
    };

    const onOpenShiftEventModal = (record) => {
        setShiftVisible(true);
        setActiveItem(record);
    };

    const onCloseShiftEventModal = () => {
        setShiftVisible(false);
        setActiveItem(null);
    };

    const cloneTitles = {
        [SidePanelItemType.Event]: "Clone Event",
        [SidePanelItemType.Booking]: "Clone Booking",
    };

    const cloneActions = {
        [SidePanelItemType.Event]: onOpenCloneEventModal,
        [SidePanelItemType.Booking]: (record) => onCloneBookings([record], dispatch),
    };

    const EditEvent = (props) => <EditAction onClick={loadItemToEdit} {...props} />;
    const CloneEvent = (props) => <CloneAction onClick={cloneActions[type]} title={cloneTitles[type]} {...props} />;
    const ShiftEvent = (props) => <ShiftEventAction onClick={onOpenShiftEventModal} {...props} />;

    const actions = {
        [SidePanelItemType.Event]: [ShiftEvent, CloneEvent, EditEvent],
        [SidePanelItemType.Booking]: [CloneEvent, EditEvent],
    };

    return (
        <div className="time-line-table">
            <TableWithGroups
                enableInfinityScrolling={true}
                loading={isLoading}
                items={items}
                filterBarProps={{type}}
                columns={columnTypes[type]}
                FilterBar={FilterBar}
                GroupTable={GroupTimelineTable}
                loadItemToEdit={loadItemToEdit}
                rowClassName={rowClassName}
                preserveFilteredData={type === SidePanelItemType.Booking}
                groupFooter={type === SidePanelItemType.Booking ? GroupBookingsTableFooter : null}
                footer={type === SidePanelItemType.Booking ? BookingsTableFooter : null}
                actions={actions[type]}></TableWithGroups>
            <CloneFromExisting selected={activeItems} isVisible={isCloneEventVisible} onClose={onCloseCloneModal}/>
            <Shift
                selected={activeItems}
                isVisible={isShiftVisible}
                onClose={onCloseShiftEventModal}
                onShift={shift}
                itemName="event"
            />
        </div>
    );
};

export default memo(TimelineTable);
