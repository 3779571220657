import {createSlice} from "@reduxjs/toolkit";


const createSliceTemplate = (name) =>
    createSlice({
        name,
        initialState: {isOpen: false, isLoading: false, type: "", item: null},
        reducers: {
            openNew(state, action) {
                state.isOpen = true;
                state.isLoading = false;
                state.type = action.payload.type;
                state.item = action.payload.default;
            },
            startLoading(state, action) {
                state.isOpen = true;
                state.isLoading = true;
                state.type = action.payload.type;
                state.item = action.payload.item;
            },
            endLoading(state, action) {
                if (state.isLoading) {
                    if (state.item && action.payload.item && state.item.Id !== action.payload.item.Id) {
                        console.log("Preventing race condition on Details Panel ");
                    } else {
                        state.isLoading = false;
                        state.item = action.payload.item;
                    }
                }
            },
            openEdit(state, action) {
                state.isOpen = true;
                if (state.isLoading && state.item && action.payload.item && state.item.Id !== action.payload.item.Id) {
                    console.log("Preventing race condition on Details Panel ");
                } else {
                    state.isLoading = false;
                    if (action.payload.type !== undefined) {
                        state.type = action.payload.type;
                    }

                    state.item = action.payload.item;
                }
            },
            continueEdit(state, action) {
                if (state.isOpen && (!state.item || (state.item && action.payload.item && state.item.Id === action.payload.item.Id))) {
                    if (action.payload.type !== undefined) {
                        state.type = action.payload.type;
                    }
                    state.item = action.payload.item;
                }
            },
            clear(state) {
                state.item = null;
            },
            close(state) {
                state.isOpen = false;
                state.isLoading = false;
                state.type = null;
                state.item = null;
            }
        },
    });

const detailsPanelSlice = createSliceTemplate("detailsPanel");
const detailsSubPanelSlice = createSliceTemplate("detailsSubPanel");

export const detailsSubPanel = detailsSubPanelSlice.actions;
export const detailsSubPanelReducer = detailsSubPanelSlice.reducer;

export const {openNew, openEdit, clear, close, startLoading, endLoading, continueEdit} = detailsPanelSlice.actions;

export default detailsPanelSlice.reducer;
