import moment from "moment";
import _ from "lodash";
import {LabourCostUnit} from "../../../constants/constants";

const calculateDuration = (mDuration) => {
    const hours = Math.floor(mDuration.asHours());
    const minutes = mDuration.minutes();
    return `${hours}:${minutes}`;
};

export const getLabourLineCostData = (labourLine) => {
    const mDuration = moment.duration(labourLine.Task.IsAllDay ? 0 : labourLine.Task.Duration).add(labourLine.MealBreak, "hours");
    const overtimeStartAfter = labourLine.Position ? labourLine.Position.Ruleset?.OvertimeStartAfter : 0;
    const overtimeMultiplier = !_.isNil(labourLine.Position?.Ruleset?.OvertimeMultiplier)
        ? labourLine.Position.Ruleset.OvertimeMultiplier
        : 0;

    let regularCost;
    let oTCost;
    let totalCost;
    let totalHours;
    if (labourLine.Task.IsLabourLineSynced) {
        const costData = labourLine.WorkCallBookings.map((b) => getCostForBookingData(labourLine, b));
        totalHours = moment.duration(_.sum(costData.map((x) => x.mDuration)));
        totalCost = _.sum(costData.map((x) => x.totalCost));
        regularCost = _.sum(costData.map((x) => x.regularCost));
        oTCost = _.sum(costData.map((x) => x.oTCost));
    } else {
        const isFlat = labourLine.Unit === LabourCostUnit.Flat;
        totalHours = moment.duration(mDuration * labourLine.Quantity || 1);
        const hours = mDuration.asHours();
        const regularTimeHours = _.min([overtimeStartAfter, hours]);

        const overtimeHours = hours - regularTimeHours;

        regularCost = isFlat ? labourLine.Cost * labourLine.Quantity : labourLine.Cost * regularTimeHours * labourLine.Quantity;
        oTCost = isFlat ? 0 : labourLine.Cost * overtimeMultiplier * overtimeHours * labourLine.Quantity;
        totalCost = isFlat ? labourLine.Cost * labourLine.Quantity : regularCost + oTCost;
    }
    return {
        totalHoursFormat: calculateDuration(totalHours),
        regularCost,
        oTCost,
        totalCost,
        totalHours: totalHours,
    };
};

export const getCostForBookingData = (labourLine, booking) => {
    let mDuration;
    if (booking.SyncTimeFrame) {
        mDuration = moment.duration(labourLine.Task.IsAllDay ? 0 : labourLine.Task.Duration).add(labourLine.MealBreak, "hours");
    } else {
        mDuration = moment.duration(booking.Duration).add(labourLine.MealBreak, "hours");
    }
    const overtimeStartAfter = labourLine.Position ? labourLine.Position.Ruleset?.OvertimeStartAfter : 0;
    const overtimeMultiplier = !_.isNil(labourLine.Position?.Ruleset?.OvertimeMultiplier)
        ? labourLine.Position.Ruleset.OvertimeMultiplier
        : 0;

    const totalHoursFormat = calculateDuration(mDuration);
    const totalHours = mDuration.asHours();
    const isFlat = labourLine.Unit === LabourCostUnit.Flat;
    const regularTimeHours = _.min([overtimeStartAfter, totalHours]);

    const overtimeHours = totalHours - regularTimeHours;

    const regularCost = isFlat ? labourLine.Cost : labourLine.Cost * regularTimeHours;
    const oTCost = isFlat ? 0 : labourLine.Cost * overtimeMultiplier * overtimeHours;

    const totalCost = isFlat ? labourLine.Cost : regularCost + oTCost;

    return {
        totalHoursFormat,
        regularCost,
        oTCost,
        totalCost,
        mDuration,
    };
};
