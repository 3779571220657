import React, {useEffect} from "react";
import {Form, message, Switch} from "antd";
import {errorFromHttpResponse, isRequestCanceled} from "../../../helpers/error";
import {useDispatch, useSelector} from "react-redux";
import {CopyOutlined} from "@ant-design/icons";
import {changePubliclyAccessible, loadReports} from "../../../services/reports";
import "./ReportsDetailsPanel.scss";
import {continueEdit} from "../../../redux/reducers/detailsPanel";

const prepareFormData = (data) => {
    const formData = {
        ...data,
    };

    return formData;
};

export const getPublicUrl = (data = {}) => {
    if (!data.SecurityKey) {
        return "—";
    }

    return `${window.location.protocol}//${window.location.host}/p/OldPaperwork/${data.Id}/${data.SecurityKey}`;
};

const ReportsDetailsPanel = () => {
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    useEffect(() => {
        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit]);

    const prepareAndUpdate = (finishedForm) => {
        dispatch(continueEdit({item: {...itemToEdit, IsPubliclyAccessible: finishedForm.IsPubliclyAccessible}}));

        changePubliclyAccessible(itemToEdit.Id, finishedForm.IsPubliclyAccessible)
            .then(() => loadReports(itemToEdit?.ProjectId, activePortfolio.Id))
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                }
            });
    };

    const onFinish = (finishedForm) => {
        prepareAndUpdate(finishedForm);
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            onFinish(values);
        } catch (err) {
            console.error(err);
        }
    };

    const onCopyUrl = (url) => {
        if (!url) return;

        navigator.clipboard.writeText(url);
        message.info("Copied");
    };

    return (
        <div className="side-panel report-side-panel">
            <div>
                This paperwork was created in an old version of Propared and is no longer editable. It may be viewed
                through the URL
                below. To communicate schedule and contact information going forward, please use the Production Book
                Screen instead.
            </div>
            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                <Form.Item
                    label="URL Enabled"
                    name="IsPubliclyAccessible"
                    valuePropName="checked"
                    className="ant-form-item-without-validation">
                    <Switch onChange={save}/>
                </Form.Item>
                <Form.Item label="URL" className="ant-form-item-without-validation" shouldUpdate>
                    {itemToEdit?.IsPubliclyAccessible && (
                        <>
                            <a href={getPublicUrl(itemToEdit)} target="blank">
                                <span className="url">{getPublicUrl(itemToEdit)} </span>
                            </a>
                            <span className="url-copy" onClick={() => onCopyUrl(getPublicUrl(itemToEdit))}>
                <CopyOutlined/>
              </span>
                        </>
                    )}
                    {!itemToEdit?.IsPubliclyAccessible && (
                        <>
                            <span className="url disabled">{getPublicUrl(itemToEdit)} </span>
                            <span className="url-copy disabled">
                <CopyOutlined/>
              </span>
                        </>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};

export default ReportsDetailsPanel;
