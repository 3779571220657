import React from "react";
import {Form, Radio} from "antd";

const DateTimeFormat = ({onChange, dateFormatLabel = "Date Format::", timeFormatLabel = "Time Format::"}) => {
    return (
        <>
            <Form.Item label={dateFormatLabel} name="DateFormat"
                       className="ant-form-item-without-validation double-line-label ">
                <Radio.Group optionType="button" buttonStyle="solid" onChange={onChange}>
                    <Radio.Button value={1}>MM-DD-YY</Radio.Button>
                    <Radio.Button value={2}>DD-MM-YY</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item label={timeFormatLabel} name="TimeFormat"
                       className="ant-form-item-without-validation double-line-label">
                <Radio.Group optionType="button" buttonStyle="solid" onChange={onChange}>
                    <Radio.Button value={1}>12 Hour</Radio.Button>
                    <Radio.Button value={2}>24 Hour</Radio.Button>
                </Radio.Group>
            </Form.Item>
        </>
    );
};

export default DateTimeFormat;
