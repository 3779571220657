import React from "react";
import moment from "moment";
import _ from "lodash";
import {Tag, message, Badge} from "antd";
import FilterBar from "./FilterBar/FilterBar";
import {startLoading, endLoading} from "../../redux/reducers/detailsPanel";
import {useDispatch, useSelector} from "react-redux";
import GroupReportsTable from "./GroupReportsTable";
import {SidePanelItemType, ApiDeliverableType} from "../../constants/constants";
import {getItem, getTemplate} from "../../services/reports";
import TableWithGroups from "../Common/Tables/TableWithGroups";
import useDateTimeFormat from "../../hooks/useDateTimeFormat";
import EditAction from "../Common/Tables/Actions/EditAction";
import "./ReportsTable.scss";

const defaultColumns = {
    name: {
        title: "Report",
        dataIndex: "Name",
        key: "Id",
        render: (name, record) => {
            return (
                <>
                    {name}{" "}
                    {record.Type && record.Type !== ApiDeliverableType.Report && (
                        <Badge className="old-paperwork-badge" count="Old Paperwork"></Badge>
                    )}
                    {_.isNil(record.Type) && <Badge className="old-paperwork-badge" count="Template"></Badge>}
                </>
            );
        },
    },
    project: {
        className: "project-column",
        title: "Project",
        dataIndex: "ProjectId",
        width: "30%",
    },
    date: {
        title: "Date",
        dataIndex: ["Report", "Date"],
        width: "10%",
    },
    location: {
        title: "Location",
        dataIndex: "none", // ["Report", "Location"],
        width: "15%",
    },
    createdBy: {
        title: "Created By",
        dataIndex: "none",
        width: "15%",
    },
};

const sortItems = (items) =>
    _.sortBy(items, [(i) => i.Type !== ApiDeliverableType.Report, (i) => i.Type === ApiDeliverableType.Name]);

const sortTemplates = (items) => _.sortBy(items, [(i) => i.Name]);

const ReportsTable = ({items = [], type, templates = []}) => {
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects.projects);
    const dateTimeFormats = useDateTimeFormat();

    function loadItemToEdit(item) {
        let itemToEditType;
        let getFunc;

        if (!_.isNil(item.Type)) {
            itemToEditType = item.Type === ApiDeliverableType.Report ? SidePanelItemType.Reports : SidePanelItemType.ReportsOld;
            getFunc = getItem;
        } else {
            itemToEditType = SidePanelItemType.ReportTemplate;
            getFunc = getTemplate;
        }

        dispatch(
            startLoading({
                item,
                type: itemToEditType,
            })
        );

        getFunc(item.Id)
            .then((item) => dispatch(endLoading({item})))
            .catch(() => message.error("Server error"));
    }

    const getProject = ({Id}) => {
        const project = projects.find((p) => p.Id === Id);
        return (
            <Tag key={Id} color={project?.Color} className="project_tag">
                {project?.Name}
            </Tag>
        );
    };

    const columns = [
        defaultColumns.name,
        {
            ...defaultColumns.project,
            render: (projectId) => (projectId ? getProject({Id: projectId}) : null),
        },
        {...defaultColumns.date, render: (date) => (date != null ? moment(date).format(dateTimeFormats.date) : "")},

        //  defaultColumns.location,
        // defaultColumns.createdBy,
    ];

    const EditReport = (props) => <EditAction onClick={loadItemToEdit} {...props} />;

    return (
        <TableWithGroups
            items={[...sortTemplates(templates), ...sortItems(items)]}
            filterBarProps={{type}}
            columns={columns}
            FilterBar={FilterBar}
            GroupTable={GroupReportsTable}
            loadItemToEdit={loadItemToEdit}
            actions={[EditReport]}></TableWithGroups>
    );
};

export default ReportsTable;
