import React, {useState, useEffect, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {ApiContactType, ApiTimelineItemType, SidePanelItemType} from "../../../../constants";
import {formatContactName} from "../../../../helpers/text";
import {createOrUpdateBooking, getBookingConflicts, getEvent, refreshTimeline} from "../../../../services/timeline";
import {loadTeam} from "../../../../services/team";
import TableWithGroups from "../../../Common/Tables/TableWithGroups";
import {BookingsConflicts} from "../../TimelineTable";
import ContactsSearchFilterBar from "./ContactsSearchFilterBar/ContactsSearchFilterBar";
import {Button, Form, Space, Switch} from "antd";
import {continueEdit, detailsSubPanel} from "../../../../redux/reducers/detailsPanel";

const ContactsSearchSubPanel = () => {
    const [isTeamMembers, setIsTeamMembers] = useState(false);
    const [isAvailableContacts, setAvailableContacts] = useState(false);
    const [conflictedContacts, setConflictedContacts] = useState({});
    const itemToEdit = useSelector((state) => state.detailsSubPanel.item);
    const parentItem = useSelector((state) => state.detailsPanel.item);
    const people = useSelector((state) => state.contacts.contacts).filter((c) => c.Type === ApiContactType.Person);
    const {ProjectId} = itemToEdit;
    const dispatch = useDispatch();

    const teamMembers = useSelector((state) => state.team.project[ProjectId] || []);

    const filteredContacts = useMemo(() => {
        let result = people;
        if (isTeamMembers) {
            const tmContactIds = teamMembers.filter((tm) => tm.ContactId).map((tm) => tm.ContactId);
            result = result.filter((p) => tmContactIds.includes(p.Id));
        }

        if (isAvailableContacts) {
            result = result.filter((p) => !conflictedContacts[p.Id]);
        }

        return result;
    }, [people, teamMembers, isTeamMembers, isAvailableContacts, conflictedContacts]);

    useEffect(() => {
        (async () => {
            const response = await getBookingConflicts(itemToEdit.Id);
            setConflictedContacts(response);
        })();
    }, [itemToEdit]);

    const columns = [
        {
            title: "Name",
            dataIndex: "FirstName",
            key: "Id",
            width: "110px",
            render: (n, item) => (
                <>
                    <BookingsConflicts bookingConflicts={conflictedContacts[item.Id]}/> {formatContactName(item)}
                </>
            ),
        },
        {
            title: "Default Roles",
            dataIndex: "Specialties",
            width: "120px",

            render: (items = []) => items.map((x) => x.Name).join(", "),
        },
        {
            title: "Default Groups",
            dataIndex: "Groups",
            width: "120px",

            render: (items = []) => items.map((x) => x.Name).join(", "),
        },
    ];

    const SelectButton = ({record}) => {
        const [doneLoading, setDoneLoading] = useState(false);
        return (
            <Button
                loading={doneLoading}
                onClick={async (e) => {
                    e.stopPropagation();
                    setDoneLoading(true);
                    const updatePayload = {
                        BookingDto: {
                            ...itemToEdit,
                            NewTeamMemberContact: {Id: record.Id},
                            PositionId: itemToEdit.LabourLine?.PositionId,
                            LabourLineId: itemToEdit.LabourLine?.Id,
                        },
                        ProjectId: itemToEdit.ProjectId,
                        NumberOfSlots: null,
                        Task: {
                            Id: itemToEdit.TaskId,
                            ProjectId: itemToEdit.ProjectId,
                        },
                        CurrentProjectId: itemToEdit.ProjectId,
                    };
                    const {BookingsDto} = await createOrUpdateBooking(updatePayload);

                    if (parentItem.ItemType === ApiTimelineItemType.Booking) {
                        const booking = await getEvent(BookingsDto[0].Id, itemToEdit.ProjectId, ApiTimelineItemType.Booking);

                        await refreshTimeline(ApiTimelineItemType.Booking);
                        await loadTeam(itemToEdit.ProjectId);

                        dispatch(
                            continueEdit({
                                item: booking,
                            })
                        );
                    }

                    if (parentItem.ItemType === ApiTimelineItemType.Task) {
                        const task = await getEvent(parentItem.Id, itemToEdit.ProjectId, ApiTimelineItemType.Task);
                        dispatch(
                            continueEdit({
                                item: task,
                            })
                        );
                        await loadTeam(itemToEdit.ProjectId);
                    }

                    setDoneLoading(false);
                    dispatch(detailsSubPanel.close());
                }}
                type="link">
                Select
            </Button>
        );
    };

    return (
        <div className="side-panel contacts-search-panel">
            <Form>
                <Space>
                    <Form.Item
                        valuePropName="checked"
                        label="Available Contacts Only"
                        name="isAvailableContacts"
                        className="ant-form-item-without-validation">
                        <Switch onChange={setAvailableContacts}></Switch>
                    </Form.Item>
                    <Form.Item
                        valuePropName="checked"
                        label="Team Members Only"
                        name="isTeamMembers"
                        className="ant-form-item-without-validation">
                        <Switch onChange={setIsTeamMembers}></Switch>
                    </Form.Item>
                </Space>
            </Form>

            <TableWithGroups
                items={filteredContacts}
                columns={columns}
                hideRowSelection={true}
                FilterBar={ContactsSearchFilterBar}
                loadItemToEdit={() => {
                }}
                actionsColumnWidth="70px"
                actions={[SelectButton]}></TableWithGroups>
        </div>
    );
};

export default ContactsSearchSubPanel;
