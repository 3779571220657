import api from "../api";
import store from "../redux/store";
import {setTimeZones} from "../redux/reducers/infrastructure";

const {dispatch} = store;

export const loadTimeZones = () =>
    api
        .get(`api/Infrastructure/GetTimeZones`)
        .then((timeZones) => {
            dispatch(setTimeZones(timeZones));
        });
