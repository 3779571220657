import React, {useEffect} from "react";
import _ from "lodash";
import {Button, Select} from "antd";
import {ApiContactType} from "../../../../constants/constants";
import {SelectWithFormItem} from "../../../Common/Input";
import {useSelector} from "react-redux";
import {loadContacts} from "../../../../services/contacts";
import {createColorTagRendered} from "../../../../helpers/Tags";
import {formatContactName} from "../../../../helpers/text";

const getLabelText = (singleContactMode) => (singleContactMode ? "Contact" : "Contacts");
const getMode = (singleContactMode) => (singleContactMode ? "" : "tags");

const ContactsForEvents = ({
                               isOptionalFieldDisabled,
                               onChanged,
                               setIsQuickCreateContactVisible,
                               setIsQuickCreateOrgVisible,
                               peopleOnly,
                               singleContactMode,
                               name = "TeamMemberContactIds",
                               allowClear,
                               label = getLabelText(singleContactMode),
                           }) => {
    const allContacts = useSelector((state) => state.contacts.contacts);

    const onTeamMembersChange = (values, items) => {
        const contactIds = items.map((i) => i.value).filter((id) => !_.isNil(id));
        onChanged({
            TeamMemberContactIds: contactIds,
        });
    };

    const onTeamMemberChange = (value, item) => {
        onChanged({TeamMemberContactIds: item ? [item.value] : []});
    };

    const getOnChangeHandler = (singleContactMode) => (singleContactMode ? onTeamMemberChange : onTeamMembersChange);

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    const peopleAndOrganizationContacts = allContacts.filter(
        (c) => c.Type === ApiContactType.Person || (!peopleOnly && c.Type === ApiContactType.Organization)
    );

    useEffect(() => {
        if (!activePortfolio) return;

        if (allContacts.length === 0) {
            loadContacts(activePortfolio.Id);
        }
    }, [activePortfolio.Id]);

    return (
        <>
            <SelectWithFormItem
                disabled={isOptionalFieldDisabled}
                label={label}
                name={name}
                allowClear={allowClear}
                dropdownClassName="people-org-ddl"
                mode={getMode(singleContactMode)}
                tagRender={createColorTagRendered("orange", true)}
                placeholder="Select contacts"
                onChanged={getOnChangeHandler(singleContactMode)}
                maxTagTextLength={30}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <div className="btn-quick-create">
                            <Button type="dashed" onClick={() => setIsQuickCreateContactVisible(true)}>
                                + Add a Contact
                            </Button>
                            &nbsp;
                            {!peopleOnly && (
                                <Button type="dashed" onClick={() => setIsQuickCreateOrgVisible(true)}>
                                    + Add an Organization
                                </Button>
                            )}
                        </div>
                    </>
                )}>
                {peopleAndOrganizationContacts.length > 0 &&
                    peopleAndOrganizationContacts.map((i) => (
                        <Select.Option label={formatContactName(i, false)} key={i.Id} value={i.Id.toString()}>
                            {formatContactName(i, false)}
                        </Select.Option>
                    ))}
            </SelectWithFormItem>
        </>
    );
};

export default ContactsForEvents;
