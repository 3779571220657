import React from "react";
import _ from "lodash";
import {Col, message, Row} from "antd";
import {formatContactName, formatTeamMemberName} from "../../helpers/text";
import FilterBar from "./FilterBar/FilterBar";
import {ApiContactType, TeamContactType} from "../../constants/constants";
import {startLoading, endLoading} from "../../redux/reducers/detailsPanel";
import {useDispatch} from "react-redux";
import GroupTeamTable from "./GroupTeamTable";
import {getTeamMember} from "../../services/team";
import TableWithGroups from "../Common/Tables/TableWithGroups";
import EditAction from "../Common/Tables/Actions/EditAction";
import Address from "../Common/Address";

const getTeamMemberName = (teamMember) => teamMember.Contact ? formatContactName(teamMember.Contact) :
    <span className="grey">Team Member {teamMember.Id}</span>;

function getContactAddress(item) {
    const {Contact} = item;
    if (!Contact || Contact.Type !== ApiContactType.Place || Contact.Addresses.length === 0) {
        return false;
    }

    return (<Row>
        <Col className="contact-block">
            <Address address={Contact.Addresses[0]}></Address>
        </Col>
    </Row>);
}

const defaultColumns = {
    name: {
        title: "Name",
        dataIndex: ["Contact", "Company"],
        key: "Id",
        width: "20%",
        render: (n, item) => getTeamMemberName(item),
    }, projectRoles: {
        title: "Project Roles",
        dataIndex: "RoleTags",
        width: "20%",
        render: (items = []) => items.map((x) => x.Name).join(", "),
    }, projectGroups: {
        title: "Project Groups",
        dataIndex: "GroupTags",
        width: "20%",
        render: (items = []) => items.map((x) => x.Name).join(", "),
    },
};

export const peopleColumns = [{
    title: "Name",
    dataIndex: ["Contact", "FirstName"],
    key: "Id",
    width: "20%",
    render: (n, item) => getTeamMemberName(item),
},

    {
        title: "Organization",
        dataIndex: ["Contact", "Parent"],
        key: "Id",
        width: "15%",
        render: (x) => formatContactName(x),
    },

    defaultColumns.projectRoles, defaultColumns.projectGroups, {
        title: "Email",
        dataIndex: ["Contact", "EmailAddresses"],
        render: (emailAddresses) => ((emailAddresses || []).length > 0 ? emailAddresses[0].EmailAddress : ""),
    },];

export const organizationsColumns = [defaultColumns.name, defaultColumns.projectRoles, defaultColumns.projectGroups];

export const locationsColumns = [defaultColumns.name, defaultColumns.projectRoles, {
    title: "Address",
    dataIndex: ["Contact", "Address"],
    render: (n, item) => getContactAddress(item),
}, {
    title: "Spaces",
    dataIndex: "spaces",
    render: (spaces) => (<span>
        {_.sortBy(spaces || [], ["Company"])
            .map((s) => formatTeamMemberName(s))
            .join(", ")}
      </span>),
},];

const contactColumns = {
    [TeamContactType.Person]: peopleColumns,
    [TeamContactType.Organization]: organizationsColumns,
    [TeamContactType.Location]: locationsColumns,
};

const TeamTable = ({items = [], contactType, loading}) => {
    const dispatch = useDispatch();

    function loadItemToEdit(teamMember) {
        dispatch(startLoading({item: teamMember, type: contactType}));
        getTeamMember(teamMember.Id)
            .then((contact) => dispatch(endLoading({item: contact})))
            .catch(() => message.error("Server error"));
    }

    const EditTeam = (props) => <EditAction onClick={loadItemToEdit} {...props} />;

    return (<TableWithGroups
        loading={loading}
        filterBarProps={{contactType}}
        items={items}
        columns={contactColumns[contactType]}
        FilterBar={FilterBar}
        GroupTable={GroupTeamTable}
        loadItemToEdit={loadItemToEdit}
        actions={[EditTeam]}></TableWithGroups>);
};

export default TeamTable;
