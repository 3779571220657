import React, {useEffect, useRef, useState} from "react";
import _ from "lodash";
import moment from "moment";
import {Button, Collapse, DatePicker, Form, Input, message, Select, Switch} from "antd";
import {
    ApiContactType,
    ApiProjectType,
    ApiTimelineItemType,
    NewRecordState,
    SidePanelItemType,
} from "../../../constants/constants";
import {errorFromHttpResponse, isRequestCanceled} from "../../../helpers/error";
import {InputWithFormItemWithRef, selectFilterOption, TextAreaWithFormItem} from "../../Common/Input";
import {useDispatch, useSelector} from "react-redux";
import {getEvent} from "../../../services/timeline";
import {
    ContainerOutlined,
    CopyOutlined,
    EyeOutlined,
    FileTextOutlined,
    InfoCircleOutlined,
    TeamOutlined,
} from "@ant-design/icons";
import {createTeamMember, loadTeam} from "../../../services/team";
import {create, getItem, loadReports, update} from "../../../services/reports";
import "./ReportsDetailsPanel.scss";
import FormProjectSelect from "../../Timeline/DetailsPanel/FormProjectSelect/FormProjectSelect";
import LocationSelect from "../../Common/Selects/LocationSelect";
import InAttendance from "./InAttendance/InAttendance";
import CreateContact from "../../Common/QuickCreate/CreateContact";
import DepartmentsNotes from "./DepartmentsNotes/DepartmentsNotes";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import {mapWCToSelect} from "../../../helpers/select";
import {getProjectTimetable} from "../../../services/projects";
import {useHistory} from "react-router-dom";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import {pathCollection} from "../../../helpers/paths";
import AuditFormItem from "../../Common/AuditFormItem";
import {continueEdit, detailsSubPanel, openEdit} from "../../../redux/reducers/detailsPanel";

const {continueEdit: continueEditSubPanel} = detailsSubPanel;

const InformationHeader = (
    <>
        <InfoCircleOutlined/> Report Information
    </>
);

const prepareFormData = (data) => {
    const formData = {
        ...data,
    };

    const locationGroups = data.TeamMemberGroups.find((g) => g.Name === "Locations");
    if (locationGroups) {
        formData.LocationIds = locationGroups.TeamMembers.map((t) => t.TeamMember.Id);
    }

    return formData;
};

const mapTeamMemberGroupsToRequest = (group) => ({
    ...group,
    TeamMembers: group.TeamMembers.map((tm) => ({Id: tm.Id, TeamMemberId: tm.TeamMember.Id, Notes: tm.Notes})),
});

const getDate = (date) => (moment.isMoment(date) ? date.utcOffset(0, true).format() : date);

export const getPublicUrl = (data = {}) => {
    if (!data.SecurityKey) {
        return "—";
    }

    return `${window.location.protocol}//${window.location.host}/p/reports/${data.Id}/${data.SecurityKey}`;
};

const getMergedTeamMemberGroups = (teamMemberGroups, newTeamMember, quickCreateTeamMemberFrom, locationIds = []) => {
    const inAttendanceGroup = teamMemberGroups.find((g) => g.Name === "In Attendance") || {
        Name: "In Attendance",
        TeamMembers: [],
    };
    let locationsGroup = teamMemberGroups.find((g) => g.Name === "Locations") || {Name: "Locations", TeamMembers: []};

    let teamMembers = inAttendanceGroup.TeamMembers.map((tm) => {
        if (!quickCreateTeamMemberFrom || tm.Id !== quickCreateTeamMemberFrom.Id) return tm;

        return {
            ...tm,
            TeamMember: newTeamMember,
        };
    });

    if (quickCreateTeamMemberFrom && !quickCreateTeamMemberFrom.Id && newTeamMember) {
        teamMembers = [
            ...teamMembers,
            {
                TeamMember: newTeamMember,
            },
        ];
    }

    let locations = (locationsGroup.TeamMembers || []).filter((tm) => locationIds.includes(tm.TeamMember.Id));
    const locationMembers = locations.map((tm) => tm.TeamMember.Id);
    const newLocations = locationIds.filter((l) => !locationMembers.includes(l));

    locations = [
        ...locations,
        ...newLocations.map((id) => ({
            TeamMember: {Id: id},
        })),
    ];

    return [
        {
            ...inAttendanceGroup,
            TeamMembers: teamMembers,
        },
        {
            ...locationsGroup,
            TeamMembers: locations,
        },
    ];
};

const ReportsDetailsPanel = ({isSubPanel}) => {
    const itemToEdit = useSelector((state) => (isSubPanel ? state.detailsSubPanel.item : state.detailsPanel.item));
    const continueEditFunc = isSubPanel ? continueEditSubPanel : continueEdit;

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const firstInputRef = useRef();
    const secondInputRef = useRef();
    const dateTimeFormats = useDateTimeFormat();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const [tasks, setTasks] = useState([]);

    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();

    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);

    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [isQuickCreateOrgVisible, setIsQuickCreateOrgVisible] = useState(false);
    const [quickCreateTeamMemberFrom, setQuickCreateTeamMemberFrom] = useState(null);

    const activeProject = useSelector((state) => state.projects.activeProject);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    useEffect(() => {
        if (itemToEdit) {
            loadTeam(itemToEdit.ProjectId);
        }
    }, [itemToEdit]);

    useEffect(() => {
        if (!itemToEdit) {
            setActiveProject();
            return;
        }

        setEditItemState(NewRecordState.ReadyForEdit);
        const formData = prepareFormData(itemToEdit);
        getProjectTimetable(itemToEdit.ProjectId).then((response) => setTasks(response.Items));

        form.setFieldsValue(formData);
    }, [itemToEdit]);

    const prepareAndUpdate = (finishedForm) => {
        const mergedTeamMemberGroups = getMergedTeamMemberGroups(
            finishedForm.TeamMemberGroups,
            finishedForm.NewTeamMember,
            quickCreateTeamMemberFrom,
            finishedForm.LocationIds
        );

        const updatePayload = {
            Id: itemToEdit.Id,
            Name: finishedForm.Name,
            TeamMemberGroups: mergedTeamMemberGroups.map(mapTeamMemberGroupsToRequest),
            Report: {...finishedForm.Report, Date: getDate(finishedForm.Report.Date)},
            Notes: null,
            HideTaskLocation: false,
            HideTaskTeam: false,
            HideTaskDepartments: false,
            HideTaskCategories: false,
            HideTaskNotes: false,
            IsPubliclyAccessible: finishedForm.IsPubliclyAccessible,
            RecipientTeamMemberIds: [],
            RecipientGroupTagIds: [],
            TaskId: finishedForm.TaskId,
            WorkCallBookingIds: [],
            CallSheet: {Date: null, LocationId: null, MeetingNotes: "", GoalsNotes: "", MiscNotes: ""},
        };

        const shouldRefreshEvent = isSubPanel;

        update(updatePayload)
            .then(() => getItem(itemToEdit.Id).then((item) => dispatch(continueEditFunc({item}))))
            .then(() => {
                if (shouldRefreshEvent) {
                    getEvent(itemToEdit.TaskId, itemToEdit.ProjectId, ApiTimelineItemType.Task).then((eventItem) =>
                        dispatch(openEdit({item: eventItem, type: SidePanelItemType.Event}))
                    );
                }
            })
            .then(() => loadReports(itemToEdit?.ProjectId, activePortfolio.Id))
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                    //Force reset fom if error!
                    form.setFieldsValue(itemToEdit);
                }
            });
    };

    function prepareAndCreate(finishedForm) {
        const payload = {
            Name: finishedForm.Name,
            IsPubliclyAccessible: true,
            TeamMemberGroups: [{Name: "In Attendance", TeamMembers: []}],
            Report: {Date: null, Notes: "", ReportNotes: [{Name: "", Notes: []}]},
            Notes: null,
            HideTaskLocation: false,
            HideTaskTeam: false,
            HideTaskDepartments: false,
            HideTaskCategories: false,
            HideTaskNotes: false,
            RecipientTeamMemberIds: [],
            RecipientGroupTagIds: [],
            TaskIds: [],
            WorkCallBookingIds: [],
            CallSheet: {Date: null, LocationId: null, MeetingNotes: "", GoalsNotes: "", MiscNotes: ""},
        };
        create(finishedForm.ProjectId, payload)
            .then(({Id}) =>
                Promise.all([
                    loadReports(activeProject.Id, activePortfolio.Id),
                    getItem(Id).then((item) => {
                        dispatch(continueEditFunc({item}));
                        setEditItemState(NewRecordState.ReadyForEdit);
                        message.success(`${finishedForm.Name} report was created`);
                    }),
                ])
            )
            .catch((err) => {
                console.error(err);
                setEditItemState(NewRecordState.Empty);
            });

        setEditItemState(NewRecordState.RequiredFieldsSubmitted);
    }

    function setActiveProject() {
        const formData = {
            ProjectId: activeProject.Id.toString(),
        };

        form.setFieldsValue(formData);
        setTimeout(() => secondInputRef.current.focus(), 200);
    }

    const onFinish = (finishedForm, forceRefresh) => {
        if (editItemState === NewRecordState.Empty) {
            prepareAndCreate(finishedForm);
        }

        if (editItemState === NewRecordState.ReadyForEdit) {
            prepareAndUpdate(finishedForm, forceRefresh);
        }
    };

    const save = async (forceRefresh) => {
        try {
            const values = isOptionalFieldDisabled ? await form.getFieldsValue() : await form.validateFields();
            if (!isOptionalFieldDisabled || (values.Name && values.ProjectId)) {
                onFinish(values, _.isBoolean(forceRefresh) ? forceRefresh : false);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const goToEvent = () => {
        history.push(urlWithPortfolioId(pathCollection.timelinePath[SidePanelItemType.Event]));
        getEvent(itemToEdit.TaskId, itemToEdit.ProjectId, ApiTimelineItemType.Task)
            .then((item) => dispatch(openEdit({item, type: SidePanelItemType.Event})))
            .catch(() => message.error("Server error"));
    };

    const onCopyUrl = (url) => {
        if (!url) return;

        navigator.clipboard.writeText(url);
        message.info("Copied");
    };

    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;
    return (
        <div className="side-panel report-side-panel with-collapse">
            <Collapse defaultActiveKey={["Information", "InAttendance", "GeneralNotes", "DepartmentsNotes"]}>
                <Collapse.Panel key="Information" header={InformationHeader}>
                    <Form form={form} labelCol={{span: 4}} wrapperCol={{span: 8}} layout="horizontal" size="middle">

                        <FormProjectSelect
                            forwardedRef={firstInputRef}
                            projects={projects}
                            projectId={itemToEdit?.ProjectId}
                            save={save}></FormProjectSelect>

                        <InputWithFormItemWithRef
                            ref={secondInputRef}
                            name="Name"
                            label="Report Name"
                            hasFeedback
                            rules={[{required: true, message: "Name"}]}
                            onChanged={save}
                            placeholder="Enter a name to create new Report"
                        />

                        <Form.Item label="Linked Event" className="ant-form-item-without-validation">
                            <Input.Group>
                                <Form.Item name="TaskId" className="ant-form-item-without-validation">
                                    <Select
                                        showSearch
                                        allowClear
                                        disabled={isOptionalFieldDisabled}
                                        style={{width: "288px"}}
                                        filterOption={selectFilterOption}
                                        onChange={save}
                                        onBlur={save}
                                        options={tasks.map((t) => mapWCToSelect(t, dateTimeFormats))}></Select>
                                </Form.Item>
                                <Form.Item className="goto-event">
                                    {itemToEdit?.TaskId && (
                                        <Button type="link" size="small" onClick={goToEvent}>
                                            <EyeOutlined/>
                                        </Button>
                                    )}
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                        <Form.Item
                            label="Date"
                            getValueProps={(a) => ({
                                value: a ? moment(a) : null,
                            })}
                            name={["Report", "Date"]}
                            className="ant-form-item-without-validation">
                            <DatePicker
                                style={{width: "100%"}}
                                format={dateTimeFormats.date}
                                onChange={save}
                                disabled={isOptionalFieldDisabled}></DatePicker>
                        </Form.Item>
                        <Form.Item label="Location" className="ant-form-item-without-validation" name={["LocationIds"]}>
                            <LocationSelect
                                onlyTeamMembers={true}
                                hideQuickCreate={true}
                                onChange={({LocationIds}) => {
                                    form.setFieldsValue({
                                        LocationIds,
                                    });
                                }}
                                onChanged={save}
                                projectId={itemToEdit?.ProjectId}
                                disabled={isOptionalFieldDisabled}></LocationSelect>
                        </Form.Item>
                        <Form.Item
                            label="URL Enabled"
                            name="IsPubliclyAccessible"
                            valuePropName="checked"
                            className="ant-form-item-without-validation">
                            <Switch disabled={isOptionalFieldDisabled} onChange={save}/>
                        </Form.Item>
                        <Form.Item label="URL" className="ant-form-item-without-validation" shouldUpdate>
                            {itemToEdit?.IsPubliclyAccessible && (
                                <>
                                    <a href={getPublicUrl(itemToEdit)} target="blank">
                                        <span className="url">{getPublicUrl(itemToEdit)} </span>
                                    </a>
                                    <span className="url-copy" onClick={() => onCopyUrl(getPublicUrl(itemToEdit))}>
                    <CopyOutlined/>
                  </span>
                                </>
                            )}
                            {!itemToEdit?.IsPubliclyAccessible && (
                                <>
                                    <span className="url disabled">{getPublicUrl(itemToEdit)} </span>
                                    <span className="url-copy disabled">
                    <CopyOutlined/>
                  </span>
                                </>
                            )}
                        </Form.Item>
                        {itemToEdit && (
                            <>
                                <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt"
                                               userProp="CreatedBy"/>
                                <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt"
                                               userProp="UpdatedBy"/>
                            </>
                        )}
                    </Form>
                </Collapse.Panel>

                <Collapse.Panel
                    key="InAttendance"
                    className="in-attendance-panel"
                    header={
                        <>
                            <TeamOutlined/> In Attendance
                        </>
                    }>
                    <Form form={form} labelCol={{span: 0}} wrapperCol={{span: 24}} layout="horizontal" size="middle">
                        <Form.Item name={["TeamMemberGroups"]} className="ant-form-item-without-validation">
                            <InAttendance
                                disabled={isOptionalFieldDisabled}
                                projectId={itemToEdit?.ProjectId}
                                setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                                setQuickCreateTeamMemberFrom={setQuickCreateTeamMemberFrom}
                                onChanged={save}
                                setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}></InAttendance>
                        </Form.Item>
                    </Form>
                </Collapse.Panel>

                <Collapse.Panel
                    key="GeneralNotes"
                    header={
                        <>
                            <ContainerOutlined/> General Notes
                        </>
                    }>
                    <Form form={form} labelCol={{span: 0}} wrapperCol={{span: 24}} layout="horizontal" size="middle">
                        <TextAreaWithFormItem
                            name={["Report", "Notes"]}
                            label={null}
                            onChanged={save}
                            placeholder="Enter General Notes"
                            useTextEditor={true}
                            disabled={isOptionalFieldDisabled}
                        />
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel
                    key="DepartmentsNotes"
                    className="departments-notes-panel"
                    header={
                        <>
                            <FileTextOutlined/> Departments Notes
                        </>
                    }>
                    <Form form={form} labelCol={{span: 0}} wrapperCol={{span: 24}} layout="horizontal" size="middle">
                        <Form.Item name={["Report", "ReportNotes"]} className="ant-form-item-without-validation">
                            <DepartmentsNotes disabled={isOptionalFieldDisabled} onChanged={save}></DepartmentsNotes>
                        </Form.Item>
                    </Form>
                </Collapse.Panel>
            </Collapse>

            <CreateContact
                apiContactType={ApiContactType.Person}
                isVisible={isQuickCreateContactVisible}
                onClose={() => setIsQuickCreateContactVisible(false)}
                onContactCreated={(contactId, contact) => {
                    const values = form.getFieldsValue();
                    createTeamMember(itemToEdit.ProjectId, {Contact: contact})
                        .then((teamMember) => {
                            onFinish({
                                ...values,
                                NewTeamMember: teamMember,
                            });
                        })
                        .then(() => loadTeam(itemToEdit.ProjectId));
                }}></CreateContact>

            <CreateContact
                apiContactType={ApiContactType.Organization}
                isVisible={isQuickCreateOrgVisible}
                onClose={() => setIsQuickCreateOrgVisible(false)}
                onContactCreated={(contactId, contact) => {
                    const values = form.getFieldsValue();
                    createTeamMember(itemToEdit.ProjectId, {Contact: contact})
                        .then((teamMember) => {
                            onFinish({
                                ...values,
                                NewTeamMember: teamMember,
                            });
                        })
                        .then(() => loadTeam(itemToEdit.ProjectId));
                }}></CreateContact>
        </div>
    );
};

export default ReportsDetailsPanel;
