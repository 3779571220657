import {Form} from "antd";
import React from "react";
import {InputNumberWithFormItem, TextAreaWithFormItem} from "../../../Common/Input";
import {formatDuration} from "../../../../helpers/time";
import {formatAmount} from "../../../../helpers/money";
import {getLabourLineCostData} from "../../Common/CostDataCalculation";

const FinancialsBlock = ({itemToEdit, save, isOptionalFieldDisabled}) => {
    const {totalHoursFormat, regularCost, oTCost, totalCost} = getLabourLineCostData(itemToEdit);
    return (
        <>
            <InputNumberWithFormItem disabled={isOptionalFieldDisabled} name="MealBreak" label="+/- Hours"
                                     onChanged={save}/>
            <TextAreaWithFormItem
                label="Details"
                disabled={isOptionalFieldDisabled}
                name="Notes"
                onChanged={save}
                autoSize={{minRows: 2}}
                placeholder="Enter details"></TextAreaWithFormItem>
            <Form.Item label="Total Hours" className="ant-form-item-without-validation">
                {formatDuration(totalHoursFormat)}
            </Form.Item>
            <Form.Item label="Regular Cost" className="ant-form-item-without-validation">
                {formatAmount(regularCost || 0)}
            </Form.Item>
            <Form.Item label="OT Cost" className="ant-form-item-without-validation">
                {formatAmount(oTCost || 0)}
            </Form.Item>
            <Form.Item label="Total Cost" className="ant-form-item-without-validation">
                {formatAmount(totalCost || 0)}
                {itemToEdit.Task.IsLabourLineSynced &&
                    <div className="grey calculated-label">(calculated from crew bookings)</div>}
            </Form.Item>
        </>
    );
};

export default FinancialsBlock;
