import {combineReducers} from "redux";
import currentUser from "./auth";
import projects from "./projects";
import contacts from "./contacts";
import resources from "./resources";
import tags from "./tags";
import detailsPanel from "./detailsPanel";
import {detailsSubPanelReducer} from "./detailsPanel"
import mainTable from "./mainTable";
import infrastructure from "./infrastructure";
import team from "./team";
import timeline from "./timeline";
import attachments from "./attachments";
import productionBooks from "./productionBooks";
import reports from "./reports";
import settings from "./settings";
import requirements from "./requirements";
import labour from "./labour";
import inviteLinks from "./inviteLinks";

export default combineReducers({
    projects,
    currentUser,
    contacts,
    resources,
    detailsPanel,
    detailsSubPanel: detailsSubPanelReducer,
    tags,
    mainTable,
    infrastructure,
    team,
    timeline,
    attachments,
    productionBooks,
    reports,
    settings,
    requirements,
    labour,
    inviteLinks,
});
