import React from "react";
import _ from "lodash";
import {Table} from "antd";
import {formatAmount} from "../../helpers/money";

export const ProjectsTableFooter = (pageData) => {
    const itemCost = _.sum(pageData.map((p) => p.ItemCost));
    const labourCost = _.sum(pageData.map((p) => p.LabourCost));

    return (
        <>
            <Table.Summary.Row className="project-summary">
                <Table.Summary.Cell index={0}> </Table.Summary.Cell>
                <Table.Summary.Cell index={1}> </Table.Summary.Cell>
                <Table.Summary.Cell index={2}> </Table.Summary.Cell>
                <Table.Summary.Cell index={3}> </Table.Summary.Cell>
                <Table.Summary.Cell index={4}> </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                    <div className="no-wrap"><b>{formatAmount(itemCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                    <div className="no-wrap"><b>{formatAmount(labourCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                    <div className="no-wrap"><b>{formatAmount(itemCost + labourCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};

export const GroupProjectsTableFooter = (pageData) => {
    const itemCost = _.sum(pageData.map((p) => p.ItemCost));
    const labourCost = _.sum(pageData.map((p) => p.LabourCost));

    return (
        <>
            <Table.Summary.Row className="project-summary">
                <Table.Summary.Cell index={0}> </Table.Summary.Cell>
                <Table.Summary.Cell index={1}> </Table.Summary.Cell>
                <Table.Summary.Cell index={2}> </Table.Summary.Cell>
                <Table.Summary.Cell index={3}> </Table.Summary.Cell>
                <Table.Summary.Cell index={4}> </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                    <div className="no-wrap"><b>{formatAmount(itemCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                    <div className="no-wrap"><b>{formatAmount(labourCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                    <div className="no-wrap"><b>{formatAmount(itemCost + labourCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};
