import React from "react";
import {Tag, message} from "antd";
import FilterBar from "./FilterBar/FilterBar";
import {startLoading, endLoading} from "../../redux/reducers/detailsPanel";
import {useDispatch} from "react-redux";
import GroupProductionBooksTable from "./GroupProductionBooksTable";
import {SidePanelItemType} from "../../constants/constants";
import {getItem} from "../../services/productionBooks";
import usePermissions from "../../hooks/usePermissions";
import ProjectsTag from "../Common/Projects/ProjectsTag";
import TableWithGroups from "../Common/Tables/TableWithGroups";
import {getPublicUrl} from "./DetailsPanel";
import EditAction from "../Common/Tables/Actions/EditAction";
import ViewAction from "../Common/Tables/Actions/ViewAction";

const allProjectsTag = <Tag>All Projects</Tag>;

const defaultColumns = {
    name: {
        title: "Production Book",
        dataIndex: "Name",
        width: "30%",
        key: "Id",
    },
    project: {
        className: "project-column",
        title: "Projects",
        dataIndex: "Schedule",
        width: "50%",
    },
    description: {
        title: "Description",
        dataIndex: "Description",
        width: "20%",
    },
};

const ProductionBooksTable = ({items = [], type, loading}) => {
    const dispatch = useDispatch();
    const {canEditProductionBook} = usePermissions();

    function loadItemToEdit(item) {
        dispatch(startLoading({item, type: SidePanelItemType.ProductionBooks}));
        getItem(item.Id)
            .then((item) => dispatch(endLoading({item})))
            .catch(() => message.error("Server error"));
    }

    const columns = [
        defaultColumns.name,
        {
            ...defaultColumns.project,
            render: (rec) => (rec?.IsAllProjects ? allProjectsTag :
                <ProjectsTag projects={rec?.Projects}></ProjectsTag>),
        },
        defaultColumns.description,
    ];

    const EditBook = (props) => <EditAction onClick={loadItemToEdit} {...props} />;
    const ViewBook = ({record}) => <ViewAction viewUrl={getPublicUrl(record)}/>;

    return (
        <TableWithGroups
            loading={loading}
            items={items}
            filterBarProps={{type}}
            columns={columns}
            FilterBar={FilterBar}
            GroupTable={GroupProductionBooksTable}
            loadItemToEdit={loadItemToEdit}
            renderCell={(checked, record, index, originNode) => (canEditProductionBook(record) ? originNode : null)}
            actions={[ViewBook, EditBook]}></TableWithGroups>
    );
};

export default ProductionBooksTable;
