import React from "react";
import moment from "moment";
import {DownOutlined, FilterFilled} from "@ant-design/icons";
import {DatePicker, Menu, Dropdown, Button} from "antd";
import "./DateRangeFilter.scss";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";

const DateRange = ({onChanged, dateTimeFormats, selectedItems}) => (
    <Menu selectable={false} style={{width: 560}}>
        <Menu.Item key="DateRange" onClick={() => {
        }} style={{height: 341}} className="date-range-menu-item">
            <div className="date-range-filter">
                <div>
                    <DatePicker.RangePicker
                        open
                        value={selectedItems}
                        format={dateTimeFormats.date}
                        bordered={false}
                        dropdownClassName="date-range-dates"
                        getPopupContainer={(trigger) => {
                            return trigger.parentNode.parentNode.childNodes[1];
                        }}
                        ranges={{
                            "Next 7 Days": [moment(), moment().add(7, "day"), true],
                            "Next 30 Days": [moment(), moment().add(30, "day"), true],
                            "Next 12 Months": [moment(), moment().add(12, "month"), true],
                        }}
                        onChange={onChanged}
                    />
                </div>
                <div className="date-range-holder"></div>
            </div>
        </Menu.Item>
    </Menu>
);

const DateRangeFilter = ({
                             items = [], selectedItems = [], onChanged = () => {
    }, isDefaultSelection = true, search
                         }) => {
    const dateTimeFormats = useDateTimeFormat();

    let filterText = "";

    if (selectedItems === null || selectedItems.length === 0) {
        filterText = "All Dates";
    } else {
        const [filterStart, filterEnd] = selectedItems;
        filterText = `${filterStart.format(dateTimeFormats.date)} - ${filterEnd.format(dateTimeFormats.date)}`;
    }

    function onFilterChanged(filterItems) {
        onChanged(filterItems || []);
    }

    return (
        <Dropdown
            trigger="click"
            overlay={
                <DateRange
                    items={items}
                    dateTimeFormats={dateTimeFormats}
                    selectedItems={selectedItems}
                    onChanged={onFilterChanged}
                    search={search}
                />
            }
            className="multiple-items-filter">
            <Button type="link" className="btn-multiple-items-filter">
                <span className="filter-text">{filterText}</span>
                {isDefaultSelection && <DownOutlined className="filter-text-down"/>}
                {!isDefaultSelection && <FilterFilled className="active-filter filter-text-down"/>}
            </Button>
        </Dropdown>
    );
};

export default DateRangeFilter;
