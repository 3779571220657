import React, {memo} from "react";
import _ from "lodash";
import moment from "moment";
import {ClockCircleFilled} from "@ant-design/icons";
import {ApiProjectType} from "../../../constants/constants";
import {useDispatch, useSelector} from "react-redux";
import Import, {CsvMapper} from "../../Common/Modal/Import/Import";
import {TimelineImportInputLabel, FieldType, getTargetFieldTypes, getCsvToFieldType, getParsedData} from "./helper";
import {refreshTimeline, onTimelineImport} from "../../../services/timeline";
import FormProjectSelect from "../DetailsPanel/FormProjectSelect/FormProjectSelect";
import TimelineInputs from "../DetailsPanel/TimelineInputs";
import {InputWithFormItem, SelectWithFormItem} from "../../Common/Input";
import {createColorTagRendered} from "../../../helpers/Tags";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import {loadTags} from "../../../services/tags";
import {setImportingData} from "../../../redux/reducers/timeline";

const parseDateAndTime = (date, time, dateFormat, timeFormat) => {
    if (!date) return null;
    return moment(`${date} ${time || ""}`, time ? `${dateFormat} ${timeFormat}` : dateFormat);
};

const TimelineCsvMapper = memo(({fileData, setErrorMessage, setParsedData}) => {
    const fieldTypes = getTargetFieldTypes();
    const csvToFieldType = getCsvToFieldType();
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);
    const dateTimeFormats = useDateTimeFormat();
    const projectId = useSelector((store) => store.timeline.importingData);
    const dispatch = useDispatch();
    const validate = (columnsMap) => {
        const mapped = _.values(columnsMap);
        if (!projectId) {
            setErrorMessage("Project required");
        } else {
            setErrorMessage(mapped.includes(FieldType.Name) ? null : "Name mapping required");
        }
    };

    const inputs = fieldTypes.map((i) => ({value: i, label: TimelineImportInputLabel[i]}));

    return (
        <CsvMapper
            fileData={fileData}
            validate={validate}
            inputs={inputs}
            fieldTypes={fieldTypes}
            csvToFieldType={csvToFieldType}
            setParsedData={setParsedData}
            getParsedData={getParsedData}
            renderPlaceholder1={() => (
                <FormProjectSelect
                    value={projectId}
                    projects={projects}
                    save={(val) => {
                        dispatch(setImportingData(val));
                        setErrorMessage(null);
                    }}></FormProjectSelect>
            )}
            renderRecordViewTitle={() => (
                <>
                    {" "}
                    <ClockCircleFilled/>
                    Preview Event:
                </>
            )}
            renderRecordView={(form, row) => (
                <>
                    <TimelineInputs
                        row={row}
                        startDate={parseDateAndTime(row.StartDate, row.StartTime, dateTimeFormats.csvDate, dateTimeFormats.csvTime)}
                        endDate={parseDateAndTime(row.EndDate, row.EndTime, dateTimeFormats.csvDate, dateTimeFormats.csvTime)}
                        showTime={!_.isEmpty(form.getFieldValue("StartTime")) || !_.isEmpty(form.getFieldValue("EndTime"))}
                        isReadOnly={true}
                        isOptionalFieldDisabled={true}
                        hideStatuses
                        dateFormat={dateTimeFormats.date}
                        dateTimeFormat={dateTimeFormats.dateTime}></TimelineInputs>
                    <InputWithFormItem name="Location" label="Location" disabled={true}/>
                    <SelectWithFormItem
                        label="People & Organizations::"
                        name="Contacts"
                        mode="tags"
                        className="double-line-label"
                        tagRender={createColorTagRendered("orange")}
                        disabled={true}
                        style={{width: "100%"}}
                    />
                </>
            )}></CsvMapper>
    );
});
const TimelineImport = ({disabled}) => {
    const dateTimeFormats = useDateTimeFormat();
    const dispatch = useDispatch();

    const prepareData = (data) => ({
        WorkCalls: [],
        FallbackMapping: [
            {MappingColumn: 0, Value: "Location"},
            {MappingColumn: 1, Value: "Assigned Team"},
        ],
        Tasks: (data || []).map((i) => {
            return {
                CategoryTagsImported: i.CategoryTags,
                DepartmentTagsImported: i.DepartmentTags,
                NewTeamMemberContacts: i.Contacts.map((c) => ({
                    FirstName: c,
                })),
                NewLocationContacts: i.Location.map((l) => ({Company: l})),
                ...i,
                StartTime: i.StartTime ? moment(i.StartTime, dateTimeFormats.csvTime).format(dateTimeFormats.importTimeFormat) : null,
                EndTime: i.EndTime ? moment(i.EndTime, dateTimeFormats.csvTime).format(dateTimeFormats.importTimeFormat) : null,
            };
        }),
    });

    const onImport = (data) => onTimelineImport(prepareData(data));
    const onTimelineImportFinish = () => Promise.all([refreshTimeline(), loadTags()]);
    const onClose = () => dispatch(setImportingData(null));

    return (
        <Import
            disabled={disabled}
            mainIcon={<ClockCircleFilled/>}
            title="Import Events from a .CSV"
            instructions={
                <p>
                    Import events from the selected .csv file. Adjust the field mappings, preview the results, and
                    import when you are
                    ready.
                </p>
            }
            onImport={onImport}
            onImportFinish={onTimelineImportFinish}
            onClose={onClose}
            CsvMapperComponent={(props) => <TimelineCsvMapper {...props} />}
            importResultError={
                <>
                    We had trouble with some of the field values for these records.
                    <br/>
                    <br/>
                    For tasks with problematic date/time values, the category tag "import error" has been added.
                    <br/>
                    <br/>
                    For tasks with a location or contact that we couldn't correctly match to an existing contact, this
                    information has been
                    appended to the notes field.
                </>
            }></Import>
    );
};

export default TimelineImport;
