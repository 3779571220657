import React from "react";
import {Table} from "antd";
import {formatAmount} from "../../../helpers/money";
import moment from "moment";
import {formatMomentDuration} from "../../../helpers/time";
import {getLabourLineCostData} from "../Common/CostDataCalculation";

export const getLabourSummary = (pageData = []) => {
    let totalQty = 0;
    let totalCost = 0;
    let totalHoursDuration = moment.duration(0, "minutes");
    let totalDuration = moment.duration(0, "minutes");

    pageData.forEach((record) => {
        const {totalCost: cost, totalHours} = getLabourLineCostData(record);
        totalCost += cost;
        totalDuration = moment.duration(record.Task.Duration).add(totalDuration.asMinutes(), "minutes");
        totalQty += record.Quantity;
        totalHoursDuration = totalHoursDuration.add(totalHours);
    });

    return {
        totalCost,
        totalQty,
        totalDuration: formatMomentDuration(totalDuration),
        totalHours: formatMomentDuration(totalHoursDuration),
    };
};

export const LabourTableFooter = (pageData) => {
    const {totalDuration, totalCost, totalQty, totalHours} = getLabourSummary(pageData);
    return (
        <>
            <Table.Summary.Row className="req-summary">
                <Table.Summary.Cell index={0}> </Table.Summary.Cell>
                <Table.Summary.Cell index={1}> </Table.Summary.Cell>
                <Table.Summary.Cell index={2}> </Table.Summary.Cell>
                <Table.Summary.Cell index={3}> </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                    <b>{totalDuration}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                    <b>{totalQty}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                    <b>{totalHours}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                    <div className="no-wrap"><b>{formatAmount(totalCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}> </Table.Summary.Cell>
                <Table.Summary.Cell index={10}> </Table.Summary.Cell>
                <Table.Summary.Cell index={11}> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};

export const GroupLabourTableFooter = (pageData) => {
    const {totalDuration, totalCost, totalQty, totalHours} = getLabourSummary(pageData);
    return (
        <>
            <Table.Summary.Row className="req-summary">
                <Table.Summary.Cell index={0}> </Table.Summary.Cell>
                <Table.Summary.Cell index={1}> </Table.Summary.Cell>
                <Table.Summary.Cell index={2}> </Table.Summary.Cell>
                <Table.Summary.Cell index={3}> </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                    <b>{totalDuration}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}> </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                    <b>{totalQty}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                    <b>{totalHours}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                    <div className="no-wrap"><b>{formatAmount(totalCost)}</b></div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9}> </Table.Summary.Cell>
                <Table.Summary.Cell index={10}> </Table.Summary.Cell>
                <Table.Summary.Cell index={11}> </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
};
