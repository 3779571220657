import React from "react";
import {Form, Popover} from "antd";
import {InputWithFormItem, SelectWithFormItem, TextAreaWithFormItem} from "../../Common/Input";
import {geTagsGroup} from "../../../helpers/Tags";
import {QuestionCircleOutlined} from "@ant-design/icons";
import DateAndTime from "./DateAndTime";
import {TagType} from "../../../constants/constants";
import TagsSelect from "../../Common/Selects/TagsSelect";

const ProjectNameLabel = () => (
    <>
        <Popover
            content="This is a special type of event created by Propared. This event’s name and dates are synced with this project’s name and dates."
            trigger="hover">
            <QuestionCircleOutlined className="form-item-label-icon"/>
        </Popover>
        Project Name
    </>
);

const TimelineInputs = ({
                            save,
                            isOptionalFieldDisabled,
                            onDateChanged,
                            startDate,
                            endDate,
                            statusOptions,
                            projectCategories,
                            categories,
                            projectDepartments,
                            departments,
                            isReadOnly,
                            isAllDay,
                            hideStatuses = false,
                            projectTimeZone,
                            showShiftBookingInfo,
                            isProjectTask,
                            isTimeOnly,
                            dateFormat,
                            timeFormat,
                            form,
                        }) => {
    return (
        <>
            <InputWithFormItem
                name="Name"
                label={isProjectTask ? <ProjectNameLabel/> : "Event"}
                hasFeedback
                rules={[{required: true, message: "Name"}]}
                onChanged={save}
                disabled={isReadOnly}
                placeholder="Enter a name to create new Event"
            />
            <DateAndTime
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                onChange={onDateChanged}
                isDisabled={isOptionalFieldDisabled}
                startDate={startDate}
                endDate={endDate}
                isAllDay={isAllDay}
                isTimeOnly={isTimeOnly}
                showTimeShiftInfo={showShiftBookingInfo}
            />

            <Form.Item wrapperCol={{offset: 8}} className="ant-form-item-without-validation">
                {projectTimeZone}
            </Form.Item>

            {!hideStatuses && (
                <SelectWithFormItem
                    label="Status"
                    name={["StatusTag", "AccountTagId"]}
                    showSearch={true}
                    allowClear={true}
                    style={{width: "100%"}}
                    placeholder="Select a Status"
                    options={statusOptions}
                    onChanged={save}
                    disabled={isOptionalFieldDisabled}
                    filterOption={(input, option) => option.title.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                />
            )}
            <TagsSelect
                tagType={TagType.Category}
                disabled={isOptionalFieldDisabled}
                label="Categories"
                name="CategoryTags"
                placeholder="Select categories"
                save={save}
                form={form}
                groups={[geTagsGroup("Project Categories", projectCategories), geTagsGroup("All Categories", categories)]}
            />
            <TagsSelect
                tagType={TagType.Department}
                disabled={isOptionalFieldDisabled}
                label="Departments"
                name="DepartmentTags"
                placeholder="Select departments"
                save={save}
                form={form}
                groups={[geTagsGroup("Project Departments", projectDepartments), geTagsGroup("All Departments", departments)]}
            />

            <TextAreaWithFormItem
                label="Details"
                disabled={isOptionalFieldDisabled}
                name="Notes"
                onChanged={save}
                useTextEditor={true}
                placeholder="Enter details"></TextAreaWithFormItem>
        </>
    );
};

export default TimelineInputs;
