import React, {useEffect} from "react";
import _ from "lodash";
import {Button, Select} from "antd";
import {ApiContactType, SidePanelItemType} from "../../../../constants/constants";
import {SelectWithFormItem} from "../../../Common/Input";
import {useDispatch, useSelector} from "react-redux";
import {loadTeam} from "../../../../services/team";
import {loadContacts} from "../../../../services/contacts";
import {detailsSubPanel} from "../../../../redux/reducers/detailsPanel";
import {createColorTagRendered} from "../../../../helpers/Tags";
import {formatContactName, formatTeamMemberName} from "../../../../helpers/text";
import {SearchOutlined} from "@ant-design/icons";

const {openEdit: openEditSubPanel} = detailsSubPanel;

const getLabelText = (singleContactMode) => (singleContactMode ? "Contact::" : "People and Organizations::");
const getMode = (singleContactMode) => (singleContactMode ? "" : "tags");

const TeamMembersForEvent = ({
                                 form,
                                 isOptionalFieldDisabled,
                                 setIsQuickCreateContactVisible,
                                 setIsQuickCreateOrgVisible,
                                 singleContactMode,
                                 showSearchContacts,
                                 peopleOnly,
                                 projectId,
                                 allowClear,
                                 onChanged = () => {
                                 },
                                 label = getLabelText(singleContactMode),
                             }) => {
    const dispatch = useDispatch();
    const allContacts = useSelector((state) => state.contacts.contacts);
    const itemToEdit = useSelector((state) => state.detailsPanel.item);

    const onTeamMembersChange = (values, items) => {
        const teamMembers = items.filter((i) => i.team);
        const groups = items.filter((i) => i.groups);
        const groupMembers = _.flatten(groups.map((g) => g.members));
        const newTeamMemberIds = _.uniq([
            ...teamMembers.map((tm) => parseInt(tm.value, 10)),
            ...groupMembers.map((m) => m.Id),
        ]).filter((tm) => !_.isNil(tm));
        const contactIds = items.map((i) => i.contact).filter((id) => !_.isNil(id));
        form.setFieldsValue({
            TeamMemberContactIds: contactIds,
            NewTeamMemberIds: newTeamMemberIds,
        });
        onChanged();
    };

    const onTeamMemberChange = (value, item) => {
        const teamMemberContactIds = item && item.contact ? [item.contact] : [];
        const teamMemberIds = item && item.team ? [value] : [];
        const newTeamMemberIds = teamMemberIds.map((id) => parseInt(id, 10));
        form.setFieldsValue({
            TeamMemberContactIds: teamMemberContactIds,
            NewTeamMemberIds: newTeamMemberIds,
            TeamMemberIds: value,
        });
        onChanged();
    };

    const getOnChangeHandler = (singleContactMode) => (singleContactMode ? onTeamMemberChange : onTeamMembersChange);

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const parentItemToEdit = useSelector((state) => state.detailsPanel.item);

    const allTeamMembers = useSelector((state) => state.team.project[projectId]) || [];

    const peopleAndOrganizations = allTeamMembers.filter(
        (t) =>
            t.Contact === null ||
            t.Contact?.Type === ApiContactType.Person ||
            (!peopleOnly && t.Contact?.Type === ApiContactType.Organization)
    );

    const allTeamMemberContactIds = allTeamMembers.map((tm) => tm.ContactId);

    const teamMemberGroups = singleContactMode
        ? []
        : _.chain(peopleAndOrganizations)
            .map((d) => d.GroupTags)
            .flatten()
            .uniqBy((x) => x.AccountTagId)
            .map((item) => ({
                group: item,
                teamMembers: peopleAndOrganizations.filter((tm) => tm.GroupTags.map((x) => x.AccountTagId).includes(item.AccountTagId)),
            }))
            .value();

    const peopleAndOrganizationContacts = allContacts.filter(
        (c) =>
            (c.Type === ApiContactType.Person || (!peopleOnly && c.Type === ApiContactType.Organization)) &&
            !allTeamMemberContactIds.includes(c.Id)
    );

    const onSearchContactsClick = () => {
        dispatch(
            openEditSubPanel({
                item: {...parentItemToEdit, PositionName: itemToEdit.LabourLine.Position?.Name},
                type: SidePanelItemType.ContactsSearch,
            })
        );
    };

    useEffect(() => {
        if (!projectId || !activePortfolio) return;

        if (allContacts.length === 0) {
            loadContacts(activePortfolio.Id);
        }

        if (allTeamMembers.length === 0) {
            loadTeam(projectId);
        }
    }, [activePortfolio.Id, projectId]);

    return (
        <>
            <SelectWithFormItem
                allowClear={allowClear}
                disabled={isOptionalFieldDisabled}
                label={label}
                className="double-line-label"
                name="TeamMemberIds"
                dropdownClassName="people-org-ddl"
                mode={getMode(singleContactMode)}
                tagRender={createColorTagRendered("orange", true)}
                placeholder="Select contacts"
                onChange={getOnChangeHandler(singleContactMode)}
                maxTagTextLength={30}
                showSearch={true}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <div className="btn-quick-create">
                            <Button type="dashed" onClick={() => setIsQuickCreateContactVisible(true)}>
                                + Add a Contact
                            </Button>
                            &nbsp;
                            {!peopleOnly && (
                                <Button type="dashed" onClick={() => setIsQuickCreateOrgVisible(true)}>
                                    + Add an Organization
                                </Button>
                            )}
                            {showSearchContacts && (
                                <Button type="dashed" onClick={() => onSearchContactsClick()}>
                                    <SearchOutlined/> Search
                                </Button>
                            )}
                        </div>
                    </>
                )}>
                {peopleAndOrganizations.length > 0 && (
                    <Select.OptGroup label="Project Team Members">
                        {peopleAndOrganizations.map((i) => (
                            <Select.Option
                                label={formatTeamMemberName(i, singleContactMode)}
                                key={i.Id}
                                value={i.Id.toString()}
                                team="true"
                                contact={i.ContactId}>
                                {formatTeamMemberName(i, singleContactMode)}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                )}
                {teamMemberGroups.length > 0 && (
                    <Select.OptGroup label="Project Groups">
                        {teamMemberGroups.map((g) => (
                            <Select.Option
                                label={g.group.Name}
                                key={g.group.AccountTagId}
                                value={g.group.AccountTagId}
                                groups="true"
                                members={g.teamMembers}>
                                {g.group.Name} ({g.teamMembers.length})
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                )}
                {peopleAndOrganizationContacts.length > 0 && (
                    <Select.OptGroup label="All Contacts">
                        {peopleAndOrganizationContacts.map((i) => (
                            <Select.Option label={formatContactName(i, false)} key={i.Id} value={`contact_${i.Id}`}
                                           contact={i.Id}>
                                {formatContactName(i, false)}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                )}
            </SelectWithFormItem>
        </>
    );
};

export default TeamMembersForEvent;
