import {List, Button, message} from "antd";
import React, {useEffect} from "react";
import _ from "lodash";
import {ResourceType} from "../../../../constants/constants";
import "./SourcesManagement.scss";
import EditSourceRow from "./EditSourceRow";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import useUrlWithPortfolioId from "../../../../hooks/useUrlWithPortfolioId";
import {pathCollection} from "../../../../helpers/paths";
import {startLoading, endLoading} from "../../../../redux/reducers/detailsPanel";

import {getRequirementItem} from "../../../../services/requirements";
import {getResource} from "../../../../services/resources";
import {setRequirementItem} from "../../../../redux/reducers/requirements";

const SourcesManagement = ({requirement, onChangeSource}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const urlWithPortfolioId = useUrlWithPortfolioId();

    const resourceId = requirement?.Resource?.Id;
    const requirementId = requirement?.Id;
    const selectedResource = useSelector((state) => state.requirements.requirementItems[requirementId] || null);
    const sources = selectedResource?.Sources?.map((s) => ({
        ...s,
        ResourceId: resourceId,
        RequirementId: requirementId
    })) || [];
    const sortedSources =
        sources.length > 0
            ? _.sortBy(sources, (s) => s.Id)
            : [{RequirementId: requirementId, Name: "Unknown", Sources: [], Value: requirement?.UnknownSource}];

    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const isIncomplete = !requirementId;

    const fetchItem = () => {
        getRequirementItem(requirementId, resourceId).then((response) => {
            dispatch(setRequirementItem(response));
        });
    };

    useEffect(() => {
        if (!requirementId) return;

        if (resourceId) fetchItem();
        else dispatch(setRequirementItem({RequirementId: requirementId}));
    }, [requirementId, resourceId, requirement?.StartDateTime, requirement?.EndDateTime]);

    const goToResource = () => {
        if (isIncomplete) return;

        history.push(urlWithPortfolioId(pathCollection.resourcesPath[ResourceType.Item]));

        setTimeout(() => {
            dispatch(startLoading({resource: requirement.Resource, type: ResourceType.Item}));
            getResource(resourceId)
                .then((item) => dispatch(endLoading({item, type: ResourceType.Item})))
                .catch(() => message.error("Server error"));
        }, 200);
    };

    const canGoToItem = resourceId > 0;
    return (
        <div hidden={isIncomplete}>
            <List
                header={
                    <div className="details-src-item">
                        <div className="source">Source</div>
                        <div className="inventory">Qty</div>
                        <div className="availability">Avail.</div>
                        <div className="cost-unit">Cost</div>
                        <div className="actions"></div>
                    </div>
                }
                size="small"
                dataSource={sortedSources}
                renderItem={(item) => {
                    return (
                        <List.Item>
                            <EditSourceRow
                                item={item}
                                onChange={() => {
                                    onChangeSource();
                                    fetchItem();
                                }}
                                activePortfolioId={activePortfolioId}
                            />
                        </List.Item>
                    );
                }}></List>

            <div className="button-container">
                <Button type="primary" size="medium" disabled={!canGoToItem} onClick={goToResource}>
                    Go To Item Record
                </Button>
            </div>
        </div>
    );
};

export default SourcesManagement;
