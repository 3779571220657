import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {mapTextToOption} from "../../../helpers/Tags";
import {setSelectedTimeZone} from "../../../redux/reducers/timeline";

const FromTimeZone = () => {
    const dispatch = useDispatch();
    const [timeZone, setTimeZone] = useState(null);
    const timeZones = useSelector((state) => state.infrastructure.timeZones).map(mapTextToOption);
    const selectedTimeZone = useSelector((state) => state.timeline.selectedTimeZone);

    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const portfolioTimeZone = useSelector((state) => state.projects.activePortfolio?.TimeZoneId);
    const projects = useSelector((state) => state.projects.projects);

    useEffect(() => {
        if (selectedTimeZone) {
            setTimeZone(selectedTimeZone);
            return;
        }

        if (selectedProjects.length === 1) {
            const projectTimeZoneId = selectedProjects[0]?.TimeZoneId || portfolioTimeZone;
            setTimeZone(projectTimeZoneId);
            return;
        }

        if (portfolioTimeZone) {
            setTimeZone(portfolioTimeZone);
            return;
        }
    }, [selectedProjects, portfolioTimeZone, projects, selectedTimeZone]);

    useEffect(() => {
        if (timeZone) {
            dispatch(setSelectedTimeZone(timeZone));
        }
    }, [timeZone, dispatch]);

    return (
        <Select
            value={timeZone}
            label="Default Time Zone"
            name="TimeZoneId"
            style={{width: "300px", textAlign: "left"}}
            options={timeZones}
            onChange={setTimeZone}
            showSearch={true}
        />
    );
};

export default FromTimeZone;
