import React, {useState} from "react";
import {Menu, Dropdown, Button, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {SidePanelItemType} from "../../../constants/constants";
import BatchEdit from "../BatchEdit";
import CloneFromExisting, {onCloneBookings} from "../CloneFromExisting";
import Shift from "../../Common/Modal/Shift";
import {exportBookings, shift} from "../../../services/timeline";

import {openNew} from "../../../redux/reducers/detailsPanel";
import {useHistory} from "react-router-dom";
import {pathCollection} from "../../../helpers/paths";
import DeleteEvents from "../DeleteEvents";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import TimelineImport from "../TimelineImport/TimelineImport";
import FromTimeZone from "./FromTimeZone";
import usePermissions from "../../../hooks/usePermissions";
import CreateReportFromEvent from "../CreateReportFromEvent/CreateReportFromEvent";
import ClearSelection from "../../Common/ClearSelection";
import AddLabourLines from "../AddLabourLines/AddLabourLines";

const selectedType = {
    [SidePanelItemType.Event]: "Events",
    [SidePanelItemType.Booking]: "Crew Bookings",
};

const ItemsSelected = ({selected, type}) => <div>{`${selected.length} ${selectedType[type]} selected`}</div>;

const TimelineTitleRowButtons = ({type}) => {
    const [isCloneEventVisible, setCloneEventVisible] = useState(false);
    const [isShiftVisible, setShiftVisible] = useState(false);
    const [isCreateReportVisible, setCreateReportVisible] = useState(false);

    const history = useHistory();
    const selected = useSelector((store) => store.mainTable.selected) || [];
    const filteredTableItems = useSelector((store) => store.mainTable.filtered) || [];
    const isBulkSelection = useSelector((store) => store.mainTable.isBulkSelection);
    const selectedTimeZone = useSelector((store) => store.timeline.selectedTimeZone);

    const urlWithPortfolioId = useUrlWithPortfolioId();
    const {canCreateEvents} = usePermissions();

    function newItemClick() {
        history.push(urlWithPortfolioId(pathCollection.timelinePath[type]));
        setTimeout(() => dispatch(openNew({type})), 100);
    }

    function handleMoreMenuClick(e) {
    }

    const onExportBookings = () => {
        let bookingIds = selected.map((b) => b.Id);
        if (bookingIds.length === 0) {
            bookingIds = filteredTableItems.map((b) => b.Id);
        }
        exportBookings(bookingIds, selectedTimeZone);
    };

    const dispatch = useDispatch();

    const nothingSelected = selected.length === 0;
    const singleSelection = selected.length === 1;

    const MoreMenu = (
        <Menu onClick={handleMoreMenuClick}>
            {type === SidePanelItemType.Event && (
                <>
                    <Menu.Item key="1" disabled={nothingSelected} onClick={() => setShiftVisible(true)}>
                        Shift
                    </Menu.Item>
                    <Menu.Item key="2" disabled={nothingSelected} onClick={() => setCloneEventVisible(true)}>
                        Clone
                    </Menu.Item>
                    <Menu.Item key="4" disabled={!singleSelection} onClick={() => setCreateReportVisible(true)}>
                        Create Report
                    </Menu.Item>
                </>
            )}

            {type === SidePanelItemType.Booking && (
                <>
                    <Menu.Item key="2" disabled={!singleSelection} onClick={() => onCloneBookings(selected, dispatch)}>
                        Clone
                    </Menu.Item>
                </>
            )}

            {type === SidePanelItemType.Event && (
                <>
                    <Menu.Item disabled={nothingSelected} key="6">
                        <AddLabourLines disabled={nothingSelected} selected={selected}></AddLabourLines>
                    </Menu.Item>
                    <Menu.Item disabled={!canCreateEvents} key="3">
                        <TimelineImport disabled={!canCreateEvents}></TimelineImport>
                    </Menu.Item>
                </>
            )}

            {type === SidePanelItemType.Booking && (
                <Menu.Item key="5" onClick={() => onExportBookings()}>
                    Export
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <Space wrap>
            {isBulkSelection && <ItemsSelected selected={selected} type={type}/>}
            <ClearSelection/>
            <FromTimeZone></FromTimeZone>
            <Dropdown trigger="click" overlay={MoreMenu}>
                <Button>
                    More <DownOutlined/>
                </Button>
            </Dropdown>
            <DeleteEvents/>
            {/* <Button danger>Delete</Button> */}
            {!isBulkSelection && (
                <>
                    <Button disabled={!canCreateEvents} type="primary" onClick={newItemClick}>
                        {type === SidePanelItemType.Event && "New Event"}
                        {type === SidePanelItemType.Booking && "New Booking"}
                    </Button>
                </>
            )}
            {isBulkSelection && <BatchEdit type={type} selected={selected}/>}

            <CreateReportFromEvent
                selected={selected}
                isVisible={isCreateReportVisible}
                onClose={() => setCreateReportVisible(false)}
            />
            <CloneFromExisting
                type={type}
                selected={selected}
                isVisible={isCloneEventVisible}
                onClose={() => setCloneEventVisible(false)}
            />
            <Shift
                selected={selected}
                isVisible={isShiftVisible}
                onClose={() => setShiftVisible(false)}
                onShift={shift}
                itemName="event"
            />
        </Space>
    );
};

export default TimelineTitleRowButtons;
