import React from "react";
import {useDispatch} from "react-redux";
import {message, Tag} from "antd";
import ProjectsTag from "../../Common/Projects/ProjectsTag";
import TableWithGroups from "../../Common/Tables/TableWithGroups";
import {RequirementType} from "../../../constants/constants";
import {endLoading, startLoading} from "../../../redux/reducers/detailsPanel";
import {LabourTableFooter, GroupLabourTableFooter} from "./LabourTableFooter";
import FilterBar from "../FilterBar/FilterBar";
import {eventDateRange, formatDuration} from "../../../helpers/time";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import EditAction from "../../Common/Tables/Actions/EditAction";
import {getLabourLine} from "../../../services/labour";
import {formatAmount} from "../../../helpers/money";
import GroupLabourTable from "./GroupLabourTable";
import {getLabourLineCostData} from "../Common/CostDataCalculation";

const allProjectsTag = <Tag>All Projects</Tag>;

const Labour = ({items, loading}) => {
    const dispatch = useDispatch();
    const formats = useDateTimeFormat();

    const columns = [
        {
            className: "project-column",
            title: "Project",
            dataIndex: ["Task", "Project"],
            key: "Id",
            render: (project) => (project ? <ProjectsTag projects={[project]}/> : allProjectsTag),
        },
        {
            title: "Event",
            key: "Id",
            dataIndex: ["Task", "Name"],
        },

        {
            title: "Timeframe",
            dataIndex: "Timeframe",
            key: "Id",
            dataIndex: ["Task"],
            width: "160px",
            render: (record) => {
                const {StartDateTime, EndDateTime, IsTimeOnly, IsAllDay} = record || {};
                if (!StartDateTime) return;
                return eventDateRange(StartDateTime, EndDateTime, formats, IsTimeOnly, IsAllDay);
            },
        },
        {
            title: "Dur.",
            dataIndex: ["Task", "Duration"],
            key: "Id",
            width: "80px",
            render: formatDuration,
        },
        {
            title: "Position",
            dataIndex: ["Position", "Name"],
            key: "Id",
            width: "120px",
        },
        {
            title: "Qty",
            dataIndex: "Quantity",
            key: "Id",
            width: "45px",
        },
        {
            title: "Total Hours",
            dataIndex: "totalHours",
            key: "Id",
            width: "75px",
        },
        {
            title: "Cost",
            dataIndex: "totalCostAdjusted",
            key: "Id",
            width: "95px",
        },
        {
            title: "Source",
            dataIndex: ["Position", "Source", "Name"],
            key: "Id",
            width: "155px",
        },

        {
            title: "Department",
            dataIndex: ["Position", "DepartmentTag"],
            key: "Id",
            width: "100px",
            render: (department) => department?.Name,
        },
    ];

    function loadItemToEdit(item) {
        dispatch(startLoading({item: item, type: RequirementType.Labour}));
        getLabourLine(item.Id)
            .then((response) => dispatch(endLoading({item: response})))
            .catch(() => message.error("Server error"));
    }

    const EditRequirement = (props) => <EditAction onClick={loadItemToEdit} {...props} />;

    const itemsWithCostData = items.map((item) => {
        let totalCostAdjusted = null;
        const {totalCost, totalHours} = getLabourLineCostData(item);

        if (item.Position) {
            totalCostAdjusted = formatAmount(totalCost);
        }
        return {...item, totalCostAdjusted, totalHours: formatDuration(totalHours)};
    });

    return (
        <TableWithGroups
            items={itemsWithCostData}
            loading={loading}
            filterBarProps={{type: RequirementType.Labour}}
            columns={columns}
            FilterBar={FilterBar}
            loadItemToEdit={loadItemToEdit}
            actions={[EditRequirement]}
            footer={LabourTableFooter}
            GroupTable={GroupLabourTable}
            groupFooter={GroupLabourTableFooter}
        />
    );
};

export default Labour;
