import React, {useEffect, useState} from "react";
import {Form} from "antd";
import _ from "lodash";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";

import {formatInventory} from "../../../../helpers/text";
import {formatTotalCost, defaultCostFactorMapper} from "../../../../helpers/money";
import {loadResourcesLookup} from "../../../../services/resources";
import {getRequirementItem} from "../../../../services/requirements";
import {setRequirementItem} from "../../../../redux/reducers/requirements";
import ResourceSelect from "../../../Common/Selects/ResourceSelect";
import CreateResourceModal from "../../../Common/QuickCreate/Resource";
import {ScreenType} from "../../../../constants/constants";
import {continueEdit} from "../../../../redux/reducers/detailsPanel";
import ResourceSearch from "./ResourceSearch";

const ItemBlock = ({form, requirement, onChangeResource, isOptionalFieldDisabled}) => {
    const dispatch = useDispatch();
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const resourceId = requirement?.Resource?.Id;
    const requirementId = requirement?.Id;

    const selectedResource = useSelector((state) => state.requirements.requirementItems[requirementId] || null);

    const [isQuickCreateItemVisible, setIsQuickCreateItemVisible] = useState(false);
    const [isResourceSearchVisible, setResourceSearchVisible] = useState(false);

    useEffect(() => {
        if (!activePortfolioId || !requirementId) return;

        loadResourcesLookup(activePortfolioId);
    }, [activePortfolioId, requirementId, form]);

    useEffect(() => {
        if (!resourceId || !requirementId) return;

        getRequirementItem(requirementId, resourceId).then((response) => {
            dispatch(setRequirementItem(response));
        });
    }, [resourceId, requirementId, form]);

    const rawSources = selectedResource?.Sources || [];
    const sources = _.sortBy(
        rawSources.map((s) => s.Value),
        (s) => s?.Name
    );

    const costFormatter = (sources) => {
        const {StartDateTime: start, EndDateTime: end} = requirement || {};

        const factorMapper = (m) => {
            const days = start && end ? moment(end).diff(start, "days") + 1 : 1;
            return {...defaultCostFactorMapper(m), days: days};
        };

        return formatTotalCost(sources, factorMapper);
    };

    const onResourceSelect = (item) => {
        form.setFieldsValue({Resource: {Id: item.Id}});
        onChangeResource(item.Id);
        setResourceSearchVisible(false);
    };

    return (
        <>
            <ResourceSelect
                label={"Item"}
                name={["Resource", "Id"]}
                form={form}
                onChanged={onChangeResource}
                onSearchClick={() => setResourceSearchVisible(true)}
                onChange={(val) =>
                    setTimeout(() => {
                        form.setFieldsValue({Resource: {Id: val}});
                        return onChangeResource(val);
                    }, 200)
                }
                disabled={isOptionalFieldDisabled}
                setIsQuickCreateItemVisible={setIsQuickCreateItemVisible}
            />

            <Form.Item label={"Total Qty Needed"} className="ant-form-item-without-validation">
                <span style={{color: "#aaa"}}>{formatInventory(sources)}</span>
            </Form.Item>

            <Form.Item label={"Total Cost"} className="ant-form-item-without-validation">
                <span style={{color: "#aaa"}}>{costFormatter(sources)}</span>
            </Form.Item>

            <CreateResourceModal
                refreshType={ScreenType.Requirements}
                visible={isQuickCreateItemVisible}
                onClose={(data) => {
                    setIsQuickCreateItemVisible(false);

                    const createdId = data?.ResourceId;
                    if (createdId > 0) {
                        loadResourcesLookup(activePortfolioId).then((items) => {
                            onChangeResource(createdId);
                            dispatch(continueEdit(requirement));
                        });
                    }
                }}
            />

            <ResourceSearch
                isVisible={isResourceSearchVisible}
                onClose={() => setResourceSearchVisible(false)}
                onSelect={onResourceSelect}></ResourceSearch>
        </>
    );
};

export default ItemBlock;
