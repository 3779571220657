import {SearchOutlined, SettingFilled, TeamOutlined} from "@ant-design/icons";
import {Menu, Dropdown, Modal, Tabs} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {pathCollection} from "../../helpers/paths";
import {formatContactName} from "../../helpers/text";
import {logout} from "../../services/auth";
import MyAccess from "./MyAccess";
import Preferences from "./Preferences";
import Profile from "./Profile";
import "./UserProfile.scss";

const UserProfileTitle = ({account}) => (
    <>
        <Avatar shape="square" className="detail_panels_avatar" size={36} icon={<TeamOutlined/>}
                src={account.Contact?.ImageUrl}/>
        <h3>{formatContactName(account.Contact)}</h3>
    </>
);

const UserProfile = () => {
    const history = useHistory();
    const UserProfileMoreMenu = (
        <Menu>
            <Menu.Item key="1" onClick={() => setVisible(true)}>
                Profile
            </Menu.Item>

            <Menu.Item key="2" onClick={() => {
            }}>
                <a target="_blank" rel="noopener noreferrer" href="https://help.propared.com">
                    Support
                </a>
            </Menu.Item>

            <Menu.Item key="3" onClick={() => logout().finally(() => history.push(pathCollection.logins.signin))}>
                Sign Out
            </Menu.Item>
        </Menu>
    );

    const [visible, setVisible] = useState(false);
    const currentUser = useSelector((strate) => strate.currentUser);
    return (
        currentUser && (
            <div className="user_profile">
                <Dropdown overlay={UserProfileMoreMenu} trigger="click">
                    <Avatar
                        shape="square"
                        className="detail_panels_avatar"
                        size={64}
                        icon={<TeamOutlined/>}
                        src={currentUser.user.Contact?.ImageUrl}
                    />
                </Dropdown>

                <Modal
                    title={<UserProfileTitle account={currentUser.user}></UserProfileTitle>}
                    onCancel={() => setVisible(false)}
                    visible={visible}
                    maskClosable={false}
                    className="user_profile_modal"
                    footer={null}>
                    {visible && (
                        <Tabs type="card">
                            <Tabs.TabPane
                                tab={
                                    <span>
                    <TeamOutlined/> Profile
                  </span>
                                }
                                key="1">
                                <Profile account={currentUser.user}></Profile>
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <span>
                    <SearchOutlined/> My Access
                  </span>
                                }
                                key="2">
                                <MyAccess></MyAccess>
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <span>
                    <SettingFilled/>
                    My Preferences
                  </span>
                                }
                                key="3">
                                <Preferences user={currentUser.user}></Preferences>
                            </Tabs.TabPane>
                        </Tabs>
                    )}
                </Modal>
            </div>
        )
    );
};

export default UserProfile;
