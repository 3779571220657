import moment from "moment";

export const timeToLong = (time, formats) => {
    const full = moment(time).format(formats.dateAtTime) //"l [at] h:mma"
    return full.replace("am", "a").replace("pm", "p");
};

export const eventDateRange = (timeStart, timeEnd, formats, IsTimeOnly, IsAllDay) => {
    if (!timeStart || !timeEnd) return "";

    const start = moment(timeStart)
    const end = moment(timeEnd);
    if (start.isSame(end)) return start.format(formats.date); //"M/DD"

    if (IsAllDay) return `${start.format(formats.date)}—${end.format(formats.date)}`;

    const endTimeFormat = start.isSame(end, "day") || IsTimeOnly ? formats.time : formats.dateTime  //"h:mma" : "M/DD h:mma";
    const startFormatted = start.format(IsTimeOnly ? formats.time : formats.dateTime); //"M/DD h:mma"
    const endFormatted = end.format(endTimeFormat);
    const needToRemoveLastSymbol = formats.dateTime === "MM/DD/YY h:mma";

    return needToRemoveLastSymbol ? `${startFormatted.substring(0, startFormatted.length - 1)}—${endFormatted.substring(0, endFormatted.length - 1)}` :
        `${startFormatted}—${endFormatted}`;
};

export const dateRange = (timeStart = null, timeEnd = null, formats) => {
    if (timeStart === null && timeEnd === null) return "";

    if (timeEnd === null) return `${moment(timeStart).format(formats.date)}`;

    return `${moment(timeStart).format(formats.date)} - ${moment(timeEnd).format(formats.date)} `;
}

export const formatDuration = (duration) => formatMomentDuration(moment.duration(duration));

export const formatMomentDuration = (mDuration) => {
    const result = [];
    const hours = Math.floor(mDuration.asHours());
    const minutes = mDuration.minutes();
    result.push(hours ? `${hours}h` : "");
    result.push(minutes ? `${minutes}m` : "");

    return result.join("");
}

export const getDaysOfMonth = (monthDate) => {
    if (!moment.isMoment(monthDate)) return [];

    const daysInMonth = monthDate.daysInMonth();

    let arrDays = [];
    let dayCounter = 1;
    while (dayCounter <= daysInMonth) {
        var current = monthDate.clone().date(dayCounter);
        arrDays.push(current);
        dayCounter++;
    }

    return arrDays;
};

