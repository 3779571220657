import {createSlice} from "@reduxjs/toolkit";

const infrastructureSlice = createSlice({
    name: "infrastructure",
    initialState: {
        timeZones: []
    },
    reducers: {
        setTimeZones(state, action) {
            state.timeZones = action.payload;
        },
    }
});

export const {setTimeZones} = infrastructureSlice.actions;

export default infrastructureSlice.reducer;
