import React, {useState, useEffect, useRef, memo, useMemo} from "react";
import {Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {setSelected, setFiltered} from "../../../redux/reducers/mainTable";
import useWindowSize from "../../../hooks/useWindowSize";
import {useVT} from "virtualizedtableforantd4";

const getRowIdDefault = (record) => record ? `${record.Id}_type_${record.ItemType || 0}` : undefined;

const TableWithGroups = ({
                             items = [],
                             columns = [],
                             FilterBar,
                             GroupTable,
                             loading,
                             rowClassName,
                             loadItemToEdit,
                             filterBarProps = {},
                             renderCell,
                             actions = [],
                             footer,
                             groupFooter,
                             enableInfinityScrolling = false,
                             preserveFilteredData = false,
                             hideRowSelection = false,
                             actionsColumnWidth,
                             sortOrder,
                             onChange,
                             onGroupByChange
                         }) => {
    const dispatch = useDispatch();
    const size = useWindowSize();

    const [filteredData, setFilteredData] = useState(items);
    const [groupBy, setGroupBy] = useState();
    const location = useLocation();
    const tableRef = useRef();
    const tableHeight = (size?.height || 800) - (tableRef.current?.offsetTop || 0) - 40;
    const [vt] = useVT(() => ({scroll: {y: tableHeight}}), [tableHeight, items]);
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const selected = useSelector((state) => state.mainTable.selected);

    useEffect(() => {
        dispatch(setSelected([]));

        if (preserveFilteredData) {
            dispatch(setFiltered([]));
        }

    }, [location, dispatch]);

    useEffect(() => {
        if (onGroupByChange) {
            onGroupByChange(groupBy);
        }
    }, [groupBy, onGroupByChange]);

    const handleChange = (pagination, filters, sorter, extra) => {
        if (onChange) {
            onChange(pagination, filters, sorter, extra);
        }
    };

    const onRowSelectionChange = (selection) => {
        const selectedIds = (selected || []).map(getRowIdDefault);
        const filteredDataIds = filteredData.map(getRowIdDefault);
        const prevSelectedItems = selectedIds.filter((i) => !filteredDataIds.includes(i));
        const selectedItems = items.filter(
            (i) => selection.includes(getRowIdDefault(i)) || prevSelectedItems.includes(getRowIdDefault(i))
        );
        dispatch(setSelected(selectedItems));
    };

    const selectedRowKeys = useMemo(() => (selected || []).map(getRowIdDefault), [selected.length]);

    function applyFilters(filtersData) {
        setFilteredData(filtersData);
        if (preserveFilteredData) dispatch(setFiltered(filtersData));
    }

    const calculatedActionsColumnWidth = actionsColumnWidth ?? (actions.length > 0 ? `${actions.length * 24 + 2}px` : "100px");
    const actionsColumn = {
        key: "action",
        className: "actions-column",
        width: calculatedActionsColumnWidth,
        alwaysVisible: true,
        render: (record) => {
            return (
                <div style={{minWidth: calculatedActionsColumnWidth}}>
                    {actions.map((ActionComponent, i) => (
                        <ActionComponent key={i} record={record}/>
                    ))}
                </div>
            );
        },
    };

    const defaultRowClassName = (record) => (record && itemToEdit && record.Id === itemToEdit.Id ? "row-selected-for-edit" : "");
    return (
        <>
            <FilterBar items={items} applyFilters={applyFilters} applyGroupBy={setGroupBy} {...filterBarProps} />
            {groupBy ? (
                <GroupTable
                    loading={loading}
                    columns={[...columns, actionsColumn]}
                    dataSource={filteredData}
                    groupByFilter={groupBy}
                    enableInfinityScrolling={enableInfinityScrolling}
                    rowSelection={{
                        type: "checkbox",
                    }}
                    onRow={(record) => ({
                        onClick: () => loadItemToEdit(record),
                    })}
                    footer={footer}
                />
            ) : (
                <Table
                    ref={tableRef}
                    loading={loading}
                    scroll={{y: tableHeight}}
                    columns={[...columns, actionsColumn]}
                    dataSource={filteredData}
                    sortOrder={sortOrder}
                    pagination={false}
                    rowKey={getRowIdDefault}
                    className="common-table"
                    size="small"
                    components={enableInfinityScrolling ? vt : null}
                    rowClassName={rowClassName || defaultRowClassName}
                    rowSelection={
                        hideRowSelection
                            ? null
                            : {
                                type: "checkbox",
                                selectedRowKeys,
                                renderCell,
                                onChange: (selected) => onRowSelectionChange(selected),
                            }
                    }
                    onRow={(record) => ({
                        onClick: () => loadItemToEdit(record),
                    })}
                    onChange={handleChange}
                    summary={filteredData.length > 1 ? groupFooter : null}
                />
            )}
        </>
    );
};

export default memo(TableWithGroups);
