import React, {useEffect, useState, memo} from "react";
import {Tabs} from "antd";

import {CalendarOutlined, ToolFilled, UnorderedListOutlined} from "@ant-design/icons";

import {getSetEventsByTypeFunc, refreshTimeline} from "../../services/timeline";
import {TimeLineTab, ApiTimelineItemType, SidePanelItemType} from "../../constants";
import TimelineTable from "./TimelineTable";
import NotesTable from "./Notes/NotesTable";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import useUrlWithPortfolioId from "../../hooks/useUrlWithPortfolioId";
import {refreshPositions} from "../../services/positions";
import {refreshNotes} from "../../services/reports";
import {timelinePath} from "../../helpers/paths";

const {TabPane} = Tabs;

const tabs = [
    {
        key: TimeLineTab.Events,
        path: timelinePath[SidePanelItemType.Event],
    },
    {
        key: TimeLineTab.Crew,
        path: timelinePath[SidePanelItemType.Booking],
    },
    {
        key: TimeLineTab.Notes,
        path: timelinePath[SidePanelItemType.Notes],
    },
];

const getTabByKey = (key) => tabs.find((tab) => tab.key === key);

const TimeLine = ({activeKey}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const selectedTimeZone = useSelector((state) => state.timeline.selectedTimeZone);
    const events = useSelector((state) => state.timeline.events);
    const crew = useSelector((state) => state.timeline.bookings);
    const notes = useSelector((state) => state.reports.notes);
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const selectedProjectsKey = selectedProjects.map((p) => p.Id).join(",");

    useEffect(() => {
        const fetchTimelineData = async () => {
            if (selectedProjects.length === 0 || !selectedTimeZone) return;

            setLoading(true);
            const itemType = activeKey === TimeLineTab.Events ? ApiTimelineItemType.Task : ApiTimelineItemType.Booking;

            const setEventsFunc = getSetEventsByTypeFunc(itemType);
            dispatch(setEventsFunc([]));
            refreshTimeline(itemType).finally(() => setLoading(false));
        };

        const fetchNotes = async () => {
            setLoading(true);
            await refreshNotes(selectedProjects.map((x) => x.Id));

            setLoading(false);
        };

        if (activeKey === TimeLineTab.Notes) {
            fetchNotes();
        } else {
            fetchTimelineData();
        }
    }, [selectedProjectsKey, selectedTimeZone, activeKey]);

    useEffect(() => {
        if (activePortfolioId) refreshPositions();
    }, [activePortfolioId]);

    const changeTab = (key) => {
        const path = getTabByKey(key).path;
        history.push(urlWithPortfolioId(path));
    };

    return (
        <Tabs onChange={changeTab} type="card" activeKey={activeKey}>
            <TabPane
                tab={
                    <span>
            <CalendarOutlined/> Events
          </span>
                }
                key={TimeLineTab.Events}>
                <TimelineTable items={events} type={SidePanelItemType.Event} loading={loading}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <UnorderedListOutlined/> Notes
          </span>
                }
                key={TimeLineTab.Notes}>
                <NotesTable items={notes} loading={loading}/>
            </TabPane>
            <TabPane
                tab={
                    <span>
            <ToolFilled/> Crew Booking
          </span>
                }
                key={TimeLineTab.Crew}>
                <TimelineTable items={crew} type={SidePanelItemType.Booking} loading={loading}/>
            </TabPane>
        </Tabs>
    );
};

export default memo(TimeLine);
