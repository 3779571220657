import React from "react";
import DateFilter from "./DateFilter";
import BookingStatusFilter from "./StatusFilter/BookingStatusFilter";
import StatusFilter from "./StatusFilter/StatusFilter";
import DepartmentsFilter from "./DepartmentsFilter";
import CategoriesFilter from "./CategoriesFilter";
import TeamFilter from "./TeamFilter";
import LocationsFilter from "./LocationsFilter";
import PositionFilter from "./PositionFilter";
import ChangedAtFilter from "../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";
import SearchFilter from "./SearchFilter/SearchFilter";
import GroupBy from "./GroupBy/GroupBy";
import FilterBar from "../../Common/Tables/FilterBar/FilterBar";
import {SidePanelItemType} from "../../../constants/constants";
import TimelineColumnsVisibility from "./ColumnsVisibility/TimelineColumnsVisibility";

const filters = {
    [SidePanelItemType.Event]: [
        DateFilter,
        StatusFilter,
        LocationsFilter,
        TeamFilter,
        CategoriesFilter,
        DepartmentsFilter,
        ChangedAtFilter,
        SearchFilter,
    ],
    [SidePanelItemType.Booking]: [DateFilter, TeamFilter, PositionFilter, BookingStatusFilter, ChangedAtFilter, SearchFilter],
};

const TimelineFilterBar = ({type, ...props}) => {
    return (
        <FilterBar
            {...props}
            filterComponents={filters[type]}
            type={type}
            GroupByComponent={GroupBy}
            ColumnsVisibilityComponent={type === SidePanelItemType.Event ? TimelineColumnsVisibility : null}
        />
    );
};

export default TimelineFilterBar;
