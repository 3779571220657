import React from "react";
import {message} from "antd";
import BatchDelete from "../../Common/Modal/BatchDelete/BatchDelete";
import {remove, loadReports, removeTemplate} from "../../../services/reports";
import {useSelector} from "react-redux";
import {ApiDeliverableType} from "../../../constants/constants";

const showFinishMessage = (singleReportSelected) => {
    message.success(`${singleReportSelected ? "Report" : "Template"} was deleted.`);
};

const DeleteReports = () => {
    const activeProject = useSelector((state) => state.projects.activeProject);
    const selected = useSelector((store) => store.mainTable.selected) || [];
    const singleReportSelected = selected.length === 1 && selected[0].Type === ApiDeliverableType.Report;
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    return (
        <BatchDelete
            selected={selected}
            disabled={selected.length !== 1}
            onFinish={() => loadReports(activeProject.Id, activePortfolio.Id)}
            title={`Are you sure you want to delete this ${singleReportSelected ? "Report" : "Template"}?`}
            batchRemove={() => (singleReportSelected ? remove(selected[0].Id) : removeTemplate(selected[0].Id))}
            onFinishMessage={() => showFinishMessage(singleReportSelected)}></BatchDelete>
    );
};
export default DeleteReports;
