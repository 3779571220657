import React from "react";
import _ from "lodash";
import {message} from "antd";
import {useDispatch} from "react-redux";

import "./PositionsTable.scss";

import FilterBar from "./FilterBar";
import {ResourceType} from "../../../constants";
import {startLoading, endLoading} from "../../../redux/reducers/detailsPanel";

import TableWithGroups from "../../Common/Tables/TableWithGroups";
import EditAction from "../../Common/Tables/Actions/EditAction";

import GroupPositionsTable from "./GroupPositionsTable";
import {getPosition} from "../../../services/positions";

const columns = [
    {
        title: "Position",
        dataIndex: "Name",
        key: "Id",
        width: "auto",
    },
    {
        title: "Source",
        dataIndex: "Source",
        key: "Src",
        width: "auto",
        render: (m) => m?.Name,
    },
    {
        title: "Department",
        dataIndex: "DepartmentTag",
        key: "Dep",
        width: "auto",
        render: (department) => department?.Name,
    },
    {
        title: "Details",
        dataIndex: "Description",
        key: "Det",
        width: "auto",
    },
];

const PositionsTable = ({positions = []}) => {
    const dispatch = useDispatch();

    function loadItemToEdit(item) {
        dispatch(startLoading({item, type: ResourceType.Position}));
        getPosition(item.Id)
            .then((item) => dispatch(endLoading({item, type: ResourceType.Position})))
            .catch(() => message.error("Server error"));
    }

    const EditItem = (props) => <EditAction onClick={loadItemToEdit} {...props} />;

    return (
        <TableWithGroups
            items={positions}
            columns={columns}
            FilterBar={FilterBar}
            loadItemToEdit={loadItemToEdit}
            actions={[EditItem].filter((a) => a)}
            GroupTable={GroupPositionsTable}></TableWithGroups>
    );
};

export default PositionsTable;
