import {createSlice} from "@reduxjs/toolkit";

const timelineSlice = createSlice({
    name: "timeline",
    initialState: {
        events: [],
        bookings: [],
        selectedTimeZone: null,
        isLoading: false,
        filterDateRange: [],
        importingData: null
    },
    reducers: {
        setEvents(state, action) {
            state.events = action.payload;
        },

        setBookings(state, action) {
            state.bookings = action.payload;
        },

        setSelectedTimeZone(state, action) {
            state.selectedTimeZone = action.payload;
        },

        setTimeLineLoading(state, action) {
            state.isLoading = action.payload;
        },

        setFilterDateRange(state, action) {
            state.filterDateRange = action.payload
        },

        setImportingData(state, action) {
            state.importingData = action.payload;
        }
    },
});

export const {
    setEvents,
    setBookings,
    setSelectedTimeZone,
    setTimeLineLoading,
    setFilterDateRange,
    setImportingData
} = timelineSlice.actions;

export default timelineSlice.reducer;
