import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, message, Row} from "antd";
import {
    ApiContactType,
    ApiTimelineItemStatus,
    ApiTimelineItemStatusName,
    ApiTimelineItemType,
    NewRecordState,
    SidePanelItemType,
} from "../../../constants/constants";
import {continueEdit, openEdit} from "../../../redux/reducers/detailsPanel";
import {formatDuration} from "../../../helpers/time";
import {errorFromHttpResponse, isRequestCanceled} from "../../../helpers/error";
import {SelectWithFormItem, TextAreaWithFormItem} from "../../Common/Input";
import {useDispatch, useSelector} from "react-redux";
import {loadContacts} from "../../../services/contacts";
import {CheckOutlined, EyeOutlined, QuestionOutlined, RedoOutlined} from "@ant-design/icons";
import {
    createOrUpdateBooking,
    getCreateOrUpdateBookingPayload,
    getEvent,
    refreshTimeline
} from "../../../services/timeline";
import TeamMembersForEvent from "./TeamMembers";
import CreateContact from "../../Common/QuickCreate/CreateContact";
import EmailList from "../../Common/ViewEditList/EmailList/EmailList";
import PhoneList from "../../Common/ViewEditList/PhoneList/PhoneList";
import {useHistory} from "react-router-dom";
import {pathCollection} from "../../../helpers/paths";
import FormProjectSelect from "./FormProjectSelect";
import {mapWCToSelect} from "../../../helpers/select";
import useUrlWithPortfolioId from "../../../hooks/useUrlWithPortfolioId";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import {loadTeam} from "../../../services/team";
import {BookingsConflicts} from "../TimelineTable";
import DateAndTime, {getDatesForApi} from "./DateAndTime";
import "./BookingDetailsPanel.scss";
import PositionSelector from "../../Requirements/Common/PositionSelector";
import {getProjectTimetable} from "../../../services/projects";
import {enableBookingsConfirm} from "./EnableBookings/EnableBookings";
import AuditFormItem from "../../Common/AuditFormItem";
import {useNavigation} from "../../../hooks/useNavigation";

const TimelineItemStatusIcon = {
    [ApiTimelineItemStatus.None]: <>&nbsp;&nbsp;&nbsp;&nbsp;</>, // <MinusOutlined />,
    [ApiTimelineItemStatus.Tentative]: <QuestionOutlined/>,
    [ApiTimelineItemStatus.Confirmed]: <CheckOutlined/>,
};

const prepareFormData = (data) => {
    const formData = {
        ...data,
        TeamMemberIds: data.PeopleOrganizations?.map((tm) => tm.Id.toString()),
        NewTeamMemberIds: data.PeopleOrganizations?.map((tm) => tm.Id.toString()),
        TeamMembers: data.PeopleOrganizations || [data.PeopleOrganizations],
    };

    return formData;
};

const TimelineDetailsPanel = () => {
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const dateTimeFormats = useDateTimeFormat();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [isQuickCreateOrgVisible, setIsQuickCreateOrgVisible] = useState(false);
    const [eventsLoading, setEventsLoading] = useState(false);
    const [workCalls, setWorkCalls] = useState([]);

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const projects = useSelector((state) => state.projects.projects);

    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const timelineProject = selectedProjects.length === 1 && !itemToEdit ? selectedProjects[0] : null;

    const allContacts = useSelector((state) => state.contacts.contacts);
    const locations = allContacts.filter((c) => c.Type === ApiContactType.Space);
    const isTaskSelected = itemToEdit?.Task || false;
    const urlWithPortfolioId = useUrlWithPortfolioId();
    const {goToContact} = useNavigation();

    const setUpWorkCalls = (projectId) => {
        setEventsLoading(true);
        getProjectTimetable(projectId)
            .then((response) => setWorkCalls(response.Items))
            .finally(() => setEventsLoading(false));
    };

    useEffect(() => {
        const projectId = itemToEdit?.ProjectId || timelineProject?.Id;
        if (projectId) setUpWorkCalls(projectId);
    }, [itemToEdit?.ProjectId, timelineProject?.Id]);

    useEffect(() => {
        if (!activePortfolio) return;

        if (allContacts.length === 0) {
            loadContacts(activePortfolio.Id);
        }
    }, [activePortfolio]);

    useEffect(() => {
        if (!itemToEdit) return;

        setEditItemState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit]);

    const prepareAndCreate = useCallback(
        (finishedForm) => {
            const payload = getCreateOrUpdateBookingPayload({
                projectId: finishedForm.ProjectId,
                taskId: finishedForm.TaskId,
                syncTimeFrame: true,
            });

            createOrUpdateBooking(payload)
                .then(({BookingsDto}) => getEvent(BookingsDto[0].Id, finishedForm.ProjectId, ApiTimelineItemType.Booking))
                .then((booking) => {
                    dispatch(
                        continueEdit({
                            item: booking,
                        })
                    );
                    refreshTimeline(ApiTimelineItemType.Booking);
                })
                .catch((err) => {
                    if (!isRequestCanceled(err)) {
                        const errorText = errorFromHttpResponse(err);
                        message.error(errorText);
                        console.error(err);
                    }
                });

            setEditItemState(NewRecordState.ReadyForEdit);
        },
        [itemToEdit?.Id, dispatch]
    );

    const prepareAndUpdate = useCallback(
        async (finishedForm, changedDates, syncTimeFrame) => {
            const newTeamMemberIds = (form.getFieldValue("NewTeamMemberIds") || []).map((id) => parseInt(id, 10));
            const teamMember = newTeamMemberIds.length > 0 ? newTeamMemberIds[0] : null;
            const teamMemberContact =
                finishedForm.TeamMemberContactIds?.length > 0 ? {Id: finishedForm.TeamMemberContactIds[0]} : null;

            const datesPayload = {
                StartDate: changedDates ? changedDates.startDate : itemToEdit.StartDate,
                EndDate: changedDates ? changedDates.endDate : itemToEdit.EndDate,
                StartTime: changedDates ? changedDates.startTime : itemToEdit.StartTime,
                EndTime: changedDates ? changedDates.endTime : itemToEdit.EndTime,
            };

            const shouldBeDatesSynced = syncTimeFrame || (!changedDates && itemToEdit.SyncTimeFrame);

            const updatePayload = getCreateOrUpdateBookingPayload({
                id: itemToEdit.Id,
                projectId: itemToEdit.ProjectId,
                taskId: itemToEdit.TaskId,
                bookingStatus: finishedForm.BookingStatus,
                roles: finishedForm.RoleTags,
                notes: finishedForm.Notes,
                teamMember,
                contact: teamMemberContact,
                syncTimeFrame: shouldBeDatesSynced,
                positionId: finishedForm.LabourLine?.PositionId,
            });

            updatePayload.BookingDto = {
                ...updatePayload.BookingDto,
                ...datesPayload,
            };

            try {
                const {BookingsDto} = await createOrUpdateBooking(updatePayload);
                if (teamMemberContact) {
                    await loadTeam(itemToEdit.ProjectId);
                    dispatch(
                        continueEdit({
                            item: {...itemToEdit, TeamMembers: [BookingsDto[0].TeamMember]},
                        })
                    );
                }

                const booking = await getEvent(BookingsDto[0].Id, itemToEdit.ProjectId, itemToEdit.ItemType);
                dispatch(
                    continueEdit({
                        item: booking,
                    })
                );
                await refreshTimeline(ApiTimelineItemType.Booking);
            } catch (err) {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                }
            }
        },
        [itemToEdit?.Id, dispatch, locations]
    );

    const onFinish = useCallback(
        (finishedForm, changedDates, syncTimeFrame) => {
            if (editItemState === NewRecordState.Empty) {
                enableBookingsConfirm({
                    taskId: finishedForm.TaskId,
                    projectId: finishedForm.ProjectId,
                    onOk: () => prepareAndCreate(finishedForm),
                });
            }
            if (editItemState === NewRecordState.ReadyForEdit) {
                prepareAndUpdate(finishedForm, changedDates, syncTimeFrame);
            }
        },
        [editItemState, prepareAndCreate, prepareAndUpdate]
    );

    const save = useCallback(async () => {
        try {
            const values = isOptionalFieldDisabled ? await form.getFieldsValue() : await form.validateFields();

            if (!isOptionalFieldDisabled || (values.TaskId && values.ProjectId)) {
                onFinish(values);
            } else {
                setUpWorkCalls(parseInt(values.ProjectId, 10));
            }
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;

    const statusOptions = [ApiTimelineItemStatus.None, ApiTimelineItemStatus.Confirmed, ApiTimelineItemStatus.Tentative].map(
        (status) => ({
            value: status,
            label: (
                <>
                    {TimelineItemStatusIcon[status]} {ApiTimelineItemStatusName[status]}
                </>
            ),
        })
    );

    const onDateChanged = (datesDto, syncTimeFrame) => {
        const changedDates = getDatesForApi(datesDto);
        onFinish(form.getFieldsValue(), changedDates, syncTimeFrame);
    };

    const onTeamMembersChange = () => {
        onFinish({
            ...form.getFieldsValue(),
            TeamMemberContactIds: form.getFieldValue("TeamMemberContactIds"),
            NewTeamMemberIds: form.getFieldValue("NewTeamMemberIds"),
        });
    };

    const goToEvent = () => {
        history.push(urlWithPortfolioId(pathCollection.timelinePath[SidePanelItemType.Event]));
        getEvent(itemToEdit.Task.Id, itemToEdit.Task.Project.Id, ApiTimelineItemType.Task)
            .then((item) => dispatch(openEdit({item, type: SidePanelItemType.Event})))
            .catch(() => message.error("Server error"));
    };

    const onResetRow = () => {
        onDateChanged(
            {
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
            },
            true
        );
    };

    const getDateAndTimeClass = () =>
        itemToEdit?.ItemType === ApiTimelineItemType.Booking && itemToEdit?.SyncTimeFrame ? "date-grey" : "";

    return (
        <div className="side-panel booking-details-panel">
            <Form
                form={form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                layout="horizontal"
                size="middle"
                initialValues={{
                    ProjectId: timelineProject?.Id.toString(),
                }}>
                <Divider orientation="left" className="divider-first">
                    Booking Info
                </Divider>

                <FormProjectSelect projects={projects} project={itemToEdit?.Project} save={save}></FormProjectSelect>
                <Input.Group>
                    {isTaskSelected ? (
                        <Form.Item label="Linked Event" className="ant-form-item-without-validation">
                            <span
                                className="selected-task">{mapWCToSelect(itemToEdit.Task, dateTimeFormats).label}</span>
                        </Form.Item>
                    ) : (
                        <SelectWithFormItem
                            loading={eventsLoading}
                            label="Linked Event"
                            name="TaskId"
                            showSearch={true}
                            style={{width: "100%"}}
                            placeholder="Select an Event"
                            hasFeedback
                            rules={[{required: true, message: "Event"}]}
                            options={workCalls.map((wc) => mapWCToSelect(wc, dateTimeFormats))}
                            onChanged={save}
                        />
                    )}
                    <Form.Item className="goto-event goto-event-labour">
                        {isTaskSelected && (
                            <Button type="link" size="small" onClick={goToEvent}>
                                <EyeOutlined/>
                            </Button>
                        )}
                    </Form.Item>
                </Input.Group>

                <Form.Item label="Event Duration" className="ant-form-item-without-validation">
                    {formatDuration(itemToEdit?.Task?.Duration) || <>&mdash;</>}
                </Form.Item>

                <DateAndTime
                    dateFormat={dateTimeFormats.date}
                    timeFormat={dateTimeFormats.time}
                    onChange={onDateChanged}
                    isDisabled={isOptionalFieldDisabled}
                    startDate={itemToEdit?.StartDateTime}
                    endDate={itemToEdit?.EndDateTime}
                    isAllDay={itemToEdit?.IsAllDay}
                    isTimeOnly={itemToEdit?.IsTimeOnly}
                    showTimeShiftInfo={false}
                    inputClassName={getDateAndTimeClass()}
                />
                <Form.Item label="Scheduled Hours" className="ant-form-item-without-validation">
                    {formatDuration(itemToEdit?.Duration) || <>&mdash;</>}
                </Form.Item>

                {itemToEdit?.ItemType === ApiTimelineItemType.Booking && itemToEdit?.SyncTimeFrame && (
                    <Form.Item wrapperCol={{offset: 8}} className="ant-form-item-without-validation">
                        Booking Time-frame is synced to its event.
                    </Form.Item>
                )}

                {itemToEdit?.ItemType === ApiTimelineItemType.Booking && !itemToEdit?.SyncTimeFrame && (
                    <Form.Item wrapperCol={{offset: 8}} className="ant-form-item-without-validation">
                        Booking Time-frame is not synced to its event.{" "}
                        <Button onClick={onResetRow} className="reset-sync" type="link" size="small">
                            <RedoOutlined/>
                        </Button>
                    </Form.Item>
                )}

                <PositionSelector
                    form={form}
                    itemToEdit={itemToEdit}
                    save={save}
                    name={["LabourLine", "PositionId"]}
                    isOptionalFieldDisabled={isOptionalFieldDisabled}></PositionSelector>

                <TeamMembersForEvent
                    form={form}
                    allowClear={true}
                    projectId={itemToEdit?.ProjectId}
                    singleContactMode={true}
                    peopleOnly={true}
                    isOptionalFieldDisabled={isOptionalFieldDisabled}
                    setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                    setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                    showSearchContacts={true}
                    onChanged={onTeamMembersChange}
                    label={
                        <>
                            <BookingsConflicts bookingConflicts={itemToEdit?.BookingConflicts}
                                               className={"form-item-label-icon"}/> Contact
                        </>
                    }></TeamMembersForEvent>

                <SelectWithFormItem
                    label="Booking Status"
                    name="BookingStatus"
                    showSearch={true}
                    style={{width: "100%"}}
                    placeholder="Select a Status"
                    options={statusOptions}
                    onChanged={save}
                    disabled={isOptionalFieldDisabled}
                />
                <TextAreaWithFormItem
                    label="Details"
                    disabled={isOptionalFieldDisabled}
                    name="Notes"
                    onChanged={save}
                    useTextEditor={true}
                    placeholder="Enter details"></TextAreaWithFormItem>

                <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="CreatedBy"/>
                <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt" userProp="UpdatedBy"/>

                {itemToEdit?.PeopleOrganizations && itemToEdit.PeopleOrganizations[0]?.Contact && (
                    <>
                        <Divider orientation="left" className="divider-first">
                            Contact Info
                        </Divider>
                        {itemToEdit.PeopleOrganizations[0].Contact.Pronouns && (
                            <Form.Item label="Pronouns" wrapperCol={24} className="ant-form-item-without-validation">
                                {itemToEdit.PeopleOrganizations[0].Contact.Pronouns}
                            </Form.Item>
                        )}
                        <Form.Item
                            name={["PeopleOrganizations", 0, "Contact", "EmailAddresses"]}
                            wrapperCol={24}
                            className="ant-form-item-without-validation">
                            <EmailList readOnly={true} onChanged={save} contactId={itemToEdit}></EmailList>
                        </Form.Item>
                        <Form.Item
                            name={["PeopleOrganizations", 0, "Contact", "PhoneNumbers"]}
                            wrapperCol={24}
                            className="ant-form-item-without-validation">
                            <PhoneList readOnly={true} onChanged={save} contactId={itemToEdit}></PhoneList>
                        </Form.Item>
                        <Row>
                            <Col span={16} offset={8}>
                                <Button type="primary"
                                        onClick={() => goToContact(itemToEdit.PeopleOrganizations[0].Contact)}>
                                    Go To Contact Record
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>

            <CreateContact
                apiContactType={ApiContactType.Person}
                isVisible={isQuickCreateContactVisible}
                onClose={() => setIsQuickCreateContactVisible(false)}
                onContactCreated={(contactId) => {
                    const values = form.getFieldsValue("NewTeamMemberIds");
                    onFinish({
                        ...values,
                        TeamMemberContactIds: [contactId],
                    });
                }}
            />
        </div>
    );
};

export default TimelineDetailsPanel;
